import { Component } from '@angular/core';

@Component({
    selector: 'ib-404',
    templateUrl: '404.component.html'
})
export class P404Component {

    constructor() {
    }

}
