import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/store.types';
import { ITermsAndConditionsPopupState } from '../../store/reducers/terms-and-conditions-popup/terms-and-conditions-popup.reducer.types';
import { ITermsOfUsePopupState } from '../../store/reducers/terms-of-use-popup/terms-of-use-popup.reducer.types';
import { termsAndConditionsPopup } from '../../store/reducers/terms-and-conditions-popup/terms-and-conditions-popup.reducer';
import { termsOfUsePopup } from '../../store/reducers/terms-of-use-popup/terms-of-use-popup.reducer';
import { destroyTermsAndConditionsPopup } from '../../store/actions/terms-and-conditions-popup/terms-and-conditions-popup.actions';
import { destroyTermsOfUsePopup } from '../../store/actions/terms-of-use-popup/terms-of-use-popup.actions';
import { IPrivacyPolicyPopupState } from '../../store/reducers/privacy-policy-popup/privacy-policy-popup.reducer.types';
import { destroyPrivacyPolicyPopup } from '../../store/actions/privacy-policy-popup/privacy-policy-popup.actions';
import { privacyPolicyPopup } from '../../store/reducers/privacy-policy-popup/privacy-policy-popup.reducer';
declare let $: any;

@Component({
    selector: 'ib-universal-popup',
    templateUrl: 'universal-popup.component.html',
    encapsulation: ViewEncapsulation.None
})

export class UniversalPopupComponent implements OnInit, OnDestroy {
    subs: Subscription[] = [];
    termsAndConditionsPopup$: Observable<ITermsAndConditionsPopupState>;
    termsOfUsePopup$: Observable<ITermsOfUsePopupState>;
    privacyPolicyPopup$: Observable<IPrivacyPolicyPopupState>;
    visible: boolean;
    title: string;
    content: string;

    constructor(
        private store: Store<AppState>
    ) {
        this.termsAndConditionsPopup$ = this.store.select(termsAndConditionsPopup);
        this.termsOfUsePopup$ = this.store.select(termsOfUsePopup);
        this.privacyPolicyPopup$ = this.store.select(privacyPolicyPopup);
    }

    ngOnInit(): void {
        this.subs.push(this.termsAndConditionsPopup$.subscribe(this.openPopup.bind(this)));
        this.subs.push(this.termsOfUsePopup$.subscribe(this.openPopup.bind(this)));
        this.subs.push(this.privacyPolicyPopup$.subscribe(this.openPopup.bind(this)));

        $(document).on('hidden.bs.modal', '#universal-popup', this.destroyAllPopups.bind(this));
    }

    openPopup(data) {
        if (data?.visible && data?.content) {
            setTimeout(() => {
                this.visible = data.visible;
                this.content = data.content;
                this.title = data.title;
            }, 100);
            setTimeout(() => {
                $('#universal-popup').modal('show');
            }, 200);
        }
    }

    destroyAllPopups() {
        this.store.dispatch(destroyTermsOfUsePopup());
        this.store.dispatch(destroyTermsAndConditionsPopup());
        this.store.dispatch(destroyPrivacyPolicyPopup());
        this.visible = false;
        this.content = null;
    }

    ngOnDestroy() {
        this.subs.forEach((sub: Subscription) => sub.unsubscribe());
    }
}
