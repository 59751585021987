<footer class="pb-4">
	<div class="row">
		<div class="col-3">
			<a href="https://blinkfitness.zendesk.com/hc/en-us" target="_blank" class="footer-menu text-dark-grey nimbussans_bold">FAQs</a>
			<p class="text-dark-grey mt-2">Got questions? We have the answers!</p>
		</div>
		<div class="col-3">
			<a href=" http://careers.blinkfitness.com/" class="footer-menu text-dark-grey nimbussans_bold">Careers at Blink</a>
			<p class="text-dark-grey mt-2">Calling all fitness fanatics and workout gurus: The best job ever awaits.</p>
			<a href=" http://careers.blinkfitness.com/" class="footer-menu text-dark-grey nimbussans_bold">See Positions</a>
		</div>
		<div class="col-3">
			<ul class="list-group">
				<li *ngFor="let item of footerMenus" class="list-unstyled">

					<a *ngIf="item.title == 'Corporate Program'" href="{{item.link}}" (click)="openCorporateModal($event)" class="footer-menu text-dark-grey"> {{ item.title }} </a>
					<a *ngIf="item.title != 'Corporate Program'" href="{{item.link}}" class="footer-menu text-dark-grey">{{ item.title }}</a>
				</li>
			</ul>
		</div>
		<div class="col-3">
      <a href="https://www.instagram.com/blinkfitness/" target="_blank">
        <i class="fab fa-instagram pr-3 text-dark-grey pull-right icon"></i>
			</a>
      <a href="https://www.twitter.com/blinkfitness" target="_blank">
        <i class="fab fa-twitter pr-3 text-dark-grey pull-right icon"></i>
			</a>
			<a href="https://www.facebook.com/BlinkFitness" target="_blank">
				<i class="fab fa-facebook-f pr-3 text-dark-grey pull-right icon"></i>
			</a>
		</div>
	</div>
	<hr>
	<div class="row">
		<p class="copyright text-dark-grey">
			© {{ date | date: 'yyyy' }} Blink Fitness. All rights reserved.
		</p>
	</div>
</footer>

<ib-corporate-membership-modal (onReady)="corporateModalRef = $event;"></ib-corporate-membership-modal>
