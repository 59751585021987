<div class="dropdown mt-4 mb-3">
    <a href="#pablo" class="navbar-brand m-0 w-100 pl-3" data-toggle="dropdown" id="headerDropdownMenuLink"><span class="nimbussans_bold">Welcome to iblink</span><br>Take me to...
        <i class="fa fa-chevron-down pr-4 text-dark-gray pull-right"></i>
        <i class="fa fa-chevron-up pr-4 text-dark-gray pull-right"></i>
    </a>
    <ul class="dropdown-menu p-0" aria-labelledby="headerDropdownMenuLink">
        <li *ngFor="let item of menusOptions">
            <a class="dropdown-item text-uppercase text-navy" [routerLink]="[item.link]">{{ item.title}}</a>
        </li>
        <li>
            <a class="dropdown-item text-uppercase text-navy" (click)="onMyPersonalTraining()">My Personal Training</a>
        </li>
    </ul>
</div>