import { IUSAState, IFacilitiesStates } from '../../../models';
import { IBaseAction, IRequestYextOptions } from '../common.types';

export enum FacilitiesStateActionTypes {
  request = '[Facilities] Load Facilities By State',
  requestSuccess = '[Facilities] Load Facilities By State Success',
  requestFailure = '[Facilities] Load Facilities By State Failure',
}
export enum FacilitiesByCityActionTypes {
  request = '[Facilities] Load Facilities By City',
  requestSuccess = '[Facilities] Load Facilities By City Success',
  requestFailure = '[Facilities] Load Facilities By City Failure',
}

export interface IFacilitiesByRegionAction extends IBaseAction {
  facilities: IFacilitiesStates;
}

export interface IFacilitiesByRegionRequest extends IRequestYextOptions {
  state: IUSAState;
}
export interface IFacilitiesByCityRequest extends IRequestYextOptions {
  state: IUSAState;
  city: string;
}
