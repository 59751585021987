import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AnalyticsService} from '../../../core/services/analytics.service';

@Component({
    selector: 'ib-download-mobile-banner',
    templateUrl: './download-mobile-banner.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DownloadMobileBannerComponent implements OnInit {

    @Input('banner') banner: any;
    @Output('onClose') onClose = new EventEmitter();


    constructor(private analyticsService: AnalyticsService) {
    }

    onPressClose() {
        this.onClose.emit();
    }

    ngOnInit() {
        if (this.banner && this.banner.active) {
            this.analyticsService.satelliteAction('iBlinkAppPopUp');
        }
    }

    onPressBanner() {
        this.analyticsService.satelliteAction('iBlinkAppDownloadLinkClick');
        window.location.href = this.banner.cta_button_link;
    }

}
