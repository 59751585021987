export class CmsPage implements ICmsPage {
    id: number;
    date: Date;
    date_gmt: Date;
    guid: Guid;
    modified: Date;
    modified_gmt: Date;
    slug: string;
    status: string;
    type: string;
    link: string;
    title: Title;
    content: Content;
    excerpt: Excerpt;
    author: number;
    featured_media: number;
    parent: number;
    menu_order: number;
    comment_status: string;
    ping_status: string;
    template: string;
    meta: any[];
    acf: CustomWPpageContent;
    _links: Links;

    constructor() {
    }
}

export interface ICmsPage<T = CustomWPpageContent> {
    id: number;
    date: Date;
    date_gmt: Date;
    guid: Guid;
    modified: Date;
    modified_gmt: Date;
    slug: string;
    status: string;
    type: string;
    link: string;
    title: Title;
    content: Content;
    excerpt: Excerpt;
    author: number;
    featured_media: number;
    parent: number;
    menu_order: number;
    comment_status: string;
    ping_status: string;
    template: string;
    meta: any[];
    acf: T;
    _links: Links;
}

export interface CustomWPpageContent {
    street?: string;
    street_2?: string;
    city?: string;
    state?: string;
    zip?: string;
    welcome_text?: string;
    background_image?: any;
    price_tag?: string;
    location_photos?: any;
    floor_plan?: any;
    equipment?: any;
    map_url?: any;
    phone?: string;
    location_hours?: any;
    top_image_1?: TopBannerImageHome;
    left_image_2?: LeftImageHome;
    right_image_3?: RightImageHome;
    left_image_4?: boolean;
    right_image_5?: boolean;
    headline_block?: HeadlineBlock;
    terms_block?:{
        terms_disclaimer?: string;
        terms: TermsBlock[]
    };
    pt_monthly_block?: PtMonthlyBlock;
    sku_block?: SkuBlock;
    sus_block?: SusBlock;
    email?: string;
    top_header_navigationitems?: TopHeaderNavigationitem[];
    header_menue_items?: HeaderMenueItem[];
    header_mobile_menue_items?: HeaderMobileMenueItem[];
    mobile_sidebar_menue?: MobileSidebarMenue[];
    membership_plans_information?: MembershipPlansInformation[];
    footer_navigation?: FooterNavigation[];
    appointment_time_list?: AppointmentTimeList[];
    fitness_goal_list?: FitnessGoalList[];
    gym_locations_list?: GymLocationsList[];
    states_list?: StatesList[];
    months_list?: MonthsList[];
    official_membership_rules?: OfficialMembershipRule[];
    startup_block?: StartupBlock;
    iblink_reward_banner: any;
    mobile_app_banner: any;
    citi_bike?: CitiBike;
}
export interface StartupBlock {
        startup_title?: string;
        startup_description?: string;
        appointment_times?: AppointmentTime[];
        fitness_goals?: FitnessGoal[];
}
export interface AppointmentTime {
    name: string;
    value: string;
}

export interface FitnessGoal {
    name: string;
    value: string;
}

export interface Guid {
    rendered: string;
}

export interface Title {
    rendered: string;
}

export interface Content {
    rendered: string;
    protected: boolean;
}

export interface Excerpt {
    rendered: string;
    protected: boolean;
}

export interface HeadlineBlock {
    active: boolean;
    header_copy: string;
    body_copy: string;
}

export interface TermsBlock {
    show_headline_block_on_a_page: boolean;
    header_copy: string;
    body_copy: TermsBlockBody[];
}

export interface TermsBlockBody {
    title: string;
}

export interface PtPlansOffered {
    title: string;
    id: string;
    price: string;
    planType?: string;
    plan?: string | any;
    old_price?: string;
    htmlTitle: any;
}

export interface PtMonthlyBlock {
    show_monthly_block_on_a_page: boolean;
    header_copy: string;
    body_copy: string;
    pt_plans_offered: PtPlansOffered[];
}

export interface SinglePlansOffered {
    title: string;
    id: string;
    price: string;
    htmlTitle: any;
    bundleId: number;
    allow_single_purchase: boolean;
    old_price?: string;
}

export interface SkuBlock {
    show_sku_block_on_a_page: boolean;
    header_copy: string;
    body_copy: string;
    single_plans_offered: SinglePlansOffered[];
}

export interface SusBlock {
    show_sus_block_on_a_page: boolean;
    header_copy: string;
    body_copy: string;
}

export interface TopHeaderNavigationitem {
    title: string;
    link: string;
    icon: string;
}

export interface HeaderMenueItem {
    title: string;
    link: string;
    classname: string;
}

export interface HeaderMobileMenueItem {
    title: string;
    link: string;
}
export interface FooterNavigation {
    title: string;
    link: string;
}
export interface MobileSidebarMenue {
    title: string;
    link: string;
}

export interface MembershipPlansInformation {
    content_1: string;
    content_2: string;
    list_1: List1[];
    list_2: List1[];
}

export interface List1 {
    name: string;
}

export interface AppointmentTimeList {
    name: string;
    value: string;
}

export interface FitnessGoalList {
    name: string;
    value: string;
}

export interface GymLocationsList {
    name: string;
    id: string;
    gymemail: string;
}

export interface StatesList {
    name: string;
    abbreviation: string;
}

export interface MonthsList {
    name: string;
    short: string;
    number: string;
    days: string;
}

export interface OfficialMembershipRule {
    item: string;
}



export interface TopBannerImageHome {
    top_banner_image: string;
    top_banner_image_small_responsive: string;
    top_title_text_copy: string;
    top_banner_button: { button_text_copy: string; button_link_url: string; omniture_tag: string };
}

export interface LeftImageHome {
    left_image: string;
    button_text_copy: string;
    button_link_url: string;
}

export interface RightImageHome {
    right_image: string;
    button_text_copy: string;
    button_link_url: string;
}

export interface Self {
    href: string;
}

export interface Collection {
    href: string;
}

export interface About {
    href: string;
}

export interface Author {
    embeddable: boolean;
    href: string;
}

export interface Reply {
    embeddable: boolean;
    href: string;
}

export interface VersionHistory {
    href: string;
}

export interface WpAttachment {
    href: string;
}

export interface Cury {
    name: string;
    href: string;
    templated: boolean;
}

export interface Links {
    self: Self[];
    collection: Collection[];
    about: About[];
    author: Author[];
    replies: Reply[];
    versionHistory: VersionHistory[];
    wpAttachment: WpAttachment[];
    curies: Cury[];
}

export interface CmsLocation {
    street: string;
    street_2: string;
    city: string;
    state: string;
    zip: string;
    welcome_text: string;
    background_image: any;
}
export interface CitiBike {
  show_citibike: boolean;
  citibike_text: string;
  citibike_url: string;
}

export interface PTTermsAndConditionsPage {
    pdf_link: string;
}
