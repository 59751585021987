import { createReducer, on } from '@ngrx/store';
import * as Actions from '../../actions/terms-and-conditions-popup/terms-and-conditions-popup.actions';
import { AppState } from '../../store.types';
import { ITermsAndConditionsPopupState } from './terms-and-conditions-popup.reducer.types';

export const initialState: ITermsAndConditionsPopupState = {
  visible: false,
  title: null,
  content: null
};

export const onLoadTermsAndConditionsPopup = (state: ITermsAndConditionsPopupState, { visible, title, content }: ITermsAndConditionsPopupState): ITermsAndConditionsPopupState => {
  return { ...state, visible, title, content };
}

export const onDestroyTermsAndConditionsPopup = (): ITermsAndConditionsPopupState => {
  return { ...initialState };
}

export const termsAndConditionsPopupReducer = createReducer(
  initialState,
  on(Actions.loadTermsAndConditionsPopup, onLoadTermsAndConditionsPopup.bind(this)),
  on(Actions.destroyTermsAndConditionsPopup, onDestroyTermsAndConditionsPopup.bind(this)),
);

export const termsAndConditionsPopup = (state: AppState) => state.termsAndConditionsPopup;