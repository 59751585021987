import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as _ from 'lodash';
import {
  FoldersActionTypes,
  IFoldersRequest,
} from '../../actions/folders/folders.actions.types';
import { YextService } from '../../../core/services/yext.service';

/**
 * Facilities By Geo Serach Effects
 */
@Injectable()
export class FoldersEffects {
  /**
   * Effect Get Facilities by Geo Search
   *
   * @description
   * This effect is triggered by Load Facilities by ID action.
   * It receives an id from facility `id`
   */
  getFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FoldersActionTypes.request),
      mergeMap((action: IFoldersRequest) =>
        this.yextService.getFolders().pipe(
          map((data: any) => ({
              type: FoldersActionTypes.requestSuccess,
              folders: data?.response?.folders,
          })),
          catchError((error) =>
            of({
              type: FoldersActionTypes.requestSuccess,
              error,
            }),
          ),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private yextService: YextService) {}
}
