<div class="row">
    <div #modalRef class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalLabel"
         aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <ng-content select=".ib-modal-title"></ng-content>
                    <button type="button" class="close cursor-pointer" data-dismiss="modal" aria-hidden="true">&times;
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <ng-content select=".ib-modal-content"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>
