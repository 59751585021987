<ib-modal (onReady)="onReady.emit($event)" #modalRef>
  <div class='ib-modal-content'>
    <div class="d-flex justify-content-center mt-4">
      <div class="col-8 text-center" [innerHTML]="message">
      </div>

    </div>
    <div class="row">
      <div class="col-8 offset-2">
        <button class="btn btn-blue btn-block error-modal-close-btn" (click)="modalRef.close()">OK
        </button>
      </div>
    </div>
  </div>
</ib-modal>