import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserService} from './user.service';
import {CmsService} from './cms.service';
import {AnalyticsService} from './analytics.service';
import {CookiesService} from './helpers/cookies.service';
import {LoggerService} from './log4ts/logger.service';
import {ConsoleLoggerService} from './log4ts/console-logger.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpsRequestInterceptor} from './core/http.interceptor';
import {CookieService} from 'ngx-cookie-service';
import {MessageService} from './error-handler/message.service';
import {HttpErrorHandler} from './error-handler/http-error-handler.service';
import {HttpService} from './core/http.service';
import {AwsService} from './core/aws.service';
import {AmplifyService} from 'aws-amplify-angular';
import {AwsAmplifyService} from './core/aws-amplify.service';
import {PaymentService} from './payment.service';
import {AuthGuard} from '../guards/auth.guard';
import {DoNotShowOnRefreshGuard} from '../guards/do-not-show-on-refresh.guard';
import {WindowRef} from './helpers/window-ref';
import {ToastService} from './helpers/toast.service';
import {ToastSecondService} from './helpers/toast-second.service';
import {PtService} from './pt.service';
import {ReferralService} from './referral.service';
import {MemberStatesService} from './helpers/member-states.service';
import {ScriptService} from './helpers/script.service';
import {SettingsService} from './settings.service';
import {LoadingScripts} from './helpers/loading-scripts';
import {PopupService} from './helpers/popup.service';
import {FacadeService} from './core/facade.service';
import {BriteverifyService} from './briteverify.service';
import {YextService} from './yext.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    AmplifyService,
    AwsAmplifyService,
    AwsService,
    AmplifyService,
    UserService,
    HttpService,
    HttpErrorHandler,
    CmsService,
    MessageService,
    PaymentService,
    AuthGuard,
    DoNotShowOnRefreshGuard,
    AnalyticsService,
    PopupService,
    WindowRef,
    ToastService,
    ToastSecondService,
    PtService,
    ReferralService,
    BriteverifyService,
    MemberStatesService,
    CookieService,
    ScriptService,
    CookiesService,
    SettingsService,
    LoadingScripts,
    {
      provide: LoggerService,
      useClass: ConsoleLoggerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsRequestInterceptor,
      multi: true
    },
    FacadeService,
    YextService,
  ],
})
export class ServicesModule {}
