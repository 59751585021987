import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CmsService } from '../../../core/services/cms.service';
import {
  TermsOfUseActionTypes,
  ITermsOfUseRequest,
} from '../../actions/terms-of-use/terms-of-use.actions.types';

@Injectable()
export class TermsOfUseEffects {
  /**
   * Effect Get Facilities
   *
   * @description
   * This effect is triggered by Load All Facilities action.
   */
  getTermsOfUse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TermsOfUseActionTypes.request),
      mergeMap((action: ITermsOfUseRequest) =>
        this.cmsService.getGlobalPageDataBySlug(action.slug).pipe(
          map((data = []) => {
            return {
              type: TermsOfUseActionTypes.requestSuccess,
              data: {
                pdf: data[0]?.acf?.pdf_link || '',
                content: data[0]?.content?.rendered || '',
                title: data[0]?.title?.rendered || ''
              }
          }},
          catchError((error) =>
            of({
              type: TermsOfUseActionTypes.requestFailure,
              error,
            }),
          ),
        ),
      ),
    ),
  ));

  constructor(private actions$: Actions, private cmsService: CmsService) {}
}
