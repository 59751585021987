import { createReducer, on } from '@ngrx/store';
import * as Actions from '../../actions/privacy-policy/privacy-policy.actions';
import {
  IPrivacyPolicyState
} from './privacy-policy.reducer.types';
import { AppState } from '../../store.types';
import { request, requestError } from './../common';
import { IPrivacyPolicyAction } from '../../actions/privacy-policy/privacy-policy.actions.types';

export const initialState: IPrivacyPolicyState = {
  loading: false,
};

export const privacyPolicyReducer = createReducer(
  initialState,
  on(Actions.loadPrivacyPolicy, request),
  on(Actions.loadPrivacyPolicySuccess, (state: IPrivacyPolicyState, { data }: IPrivacyPolicyAction): IPrivacyPolicyState => {
    return {
      ...state,
      loading: false,
      error: null,
      data: {
        ...state.data,
        ...data,
      },
  }}),
  on(Actions.loadPrivacyPolicyFailure, requestError),
);

/**
 * Gets privacyPolicy reducer
 * @param {AppState} state
 * @returns {IPrivacyPolicyState}
 *  The privacyPolicy state
 */
export const privacyPolicy = (state: AppState): IPrivacyPolicyState =>
  state.privacyPolicy;

