import { createAction, props } from '@ngrx/store';
import { PrivacyPolicyPopupActionTypes } from './privacy-policy-popup.types';

export const loadPrivacyPolicyPopup = createAction(
    PrivacyPolicyPopupActionTypes.loadPrivacyPolicyPopup,
    props<{visible: boolean, content: string, title: string}>(),
);

export const destroyPrivacyPolicyPopup = createAction(
    PrivacyPolicyPopupActionTypes.destroyPrivacyPolicyPopup
);
