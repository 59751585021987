import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { facilitiesReducer } from './facilities/facilities-reducer';
import { facilitiesByRegionReducer } from './facilities-by-region/facilities-by-region.reducer';
import { facilitiesByGeoReducer } from './facilities-by-geo/facilities-by-geo.reducer';
import { foldersReducer } from './folders/folders.reducer';
import { termsAndConditionsReducer } from './terms-and-conditions/terms-and-conditions.reducer';
import { termsAndConditionsPopupReducer } from './terms-and-conditions-popup/terms-and-conditions-popup.reducer';
import { termsOfUseReducer } from './terms-of-use/terms-of-use.reducer';
import { termsOfUsePopupReducer } from './terms-of-use-popup/terms-of-use-popup.reducer';
import { privacyPolicyReducer } from './privacy-policy/privacy-policy.reducer';
import { privacyPolicyPopupReducer } from './privacy-policy-popup/privacy-policy-popup.reducer';
import { environment } from '../../../environments/environment';
import { AppState } from '../store.types';

export const reducers: ActionReducerMap<AppState> = {
  facilities: facilitiesReducer,
  facilitiesByRegion: facilitiesByRegionReducer,
  facilitiesByGeo: facilitiesByGeoReducer,
  folders: foldersReducer,
  termsAndConditions: termsAndConditionsReducer,
  termsAndConditionsPopup: termsAndConditionsPopupReducer,
  termsOfUse: termsOfUseReducer,
  termsOfUsePopup: termsOfUsePopupReducer,
  privacyPolicy: privacyPolicyReducer,
  privacyPolicyPopup: privacyPolicyPopupReducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
