import { createReducer, on } from '@ngrx/store';
import * as Actions from '../../actions/privacy-policy-popup/privacy-policy-popup.actions';
import { AppState } from '../../store.types';
import { IPrivacyPolicyPopupState } from './privacy-policy-popup.reducer.types';

export const initialState: IPrivacyPolicyPopupState = {
  visible: false,
  title: null,
  content: null
};

export const onLoadPrivacyPolicyPopup = (state: IPrivacyPolicyPopupState, { visible, content, title }: IPrivacyPolicyPopupState): IPrivacyPolicyPopupState => {
  return { ...state, visible, title, content };
}

export const onDestroyPrivacyPolicyPopup = (): IPrivacyPolicyPopupState => {
  return { ...initialState };
}

export const privacyPolicyPopupReducer = createReducer(
  initialState,
  on(Actions.loadPrivacyPolicyPopup, onLoadPrivacyPolicyPopup.bind(this)),
  on(Actions.destroyPrivacyPolicyPopup, onDestroyPrivacyPolicyPopup.bind(this)),
);

export const privacyPolicyPopup = (state: AppState) => state.privacyPolicyPopup;