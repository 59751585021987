import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AmplifyService } from 'aws-amplify-angular';
import { LoggerService } from './log4ts/logger.service';

@Injectable()
export class BriteverifyService {
  /**
   * BriteverifyService constructor
   * @param {AmplifyService} amplifyService
   */
  constructor(
    public amplifyService: AmplifyService,
    private logger: LoggerService
  ) {
    this.logger.info('-- BriteverifyService init --');
  }

  validateEmailBriteverify(email: string): Observable<any> {
    return from(this.amplifyService.api().post('iBlinkWebMosoAPI', '/verify-email', { body: { email } }));
  }
}
