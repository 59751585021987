import { FormControl, FormGroup } from '@angular/forms';
import { Location } from '@blinkfitness/blink-yext-api/dist/lib/models/yextLocations.model';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import * as AppConstants from './const/constants';
import { FacilityYext, IDistance, IFacilitiesStates, Month } from '../models';




export function calculateTax(price: string, taxRite: number): string {
    const tax = Number(price) * taxRite;
    return tax.toFixed(2);
}

export function grandTotal(price, tax): string {
    const total = Number(price) + Number(tax);
    return total.toFixed(2);
}

/**
 * Clean All Subscriptions. Call function from ngOnDestroy()
 * cleanSubscriptions(): void
 */
export function cleanSubscriptions(subscriptions: Subscription[]): void {
    subscriptions.forEach((subscription: Subscription) => subscription?.unsubscribe());
}

/**
 * Validation all form controls
 * @param {FormGroup} formGroup
 */
export function validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
            control.markAsDirty({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            validateAllFormFields(control);
        }
    });
}

/**
 * unValidate All Form controls
 * @param {FormGroup} formGroup
 */
export function unValidateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsUntouched({onlySelf: true});
        } else if (control instanceof FormGroup) {
            unValidateAllFormFields(control);
        }
    });
}


export function resetInputs(formGroup: FormGroup): void {
    formGroup.reset();
}

/**
 * Get Current Year
 * @returns {number}
 */
export function getCurrentYear(): number {
    const d = new Date();
    return d.getFullYear();
}

/**
 * Get Years limit (currently = 51)
 * @returns {any[]}
 */
export function getYearsLimit(): any[] {
    const yearsList = [];
    const d = new Date();
    const cy = d.getFullYear();
    for (let i = 0; i < 51; i++) {
        yearsList.push(cy + i);
    }
    return yearsList;
}

/**
 * getCardType(number): string
 * checking credit card numbers and returning creadit card name
 * @param number
 * @returns {string}
 */
export function getCardType(number: string): string {
    // visa
    let re = new RegExp('^4');
    if (number.match(re) !== null) {
        return 'Visa';
    }
    // Mastercard
    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) {
        return 'Mastercard';
    }
    // AMEX
    re = new RegExp('^3[47]');
    if (number.match(re) !== null) {
        return 'AmericanExpress';
    }
    // Discover
    re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
    if (number.match(re) !== null) {
        return 'Discover';
    }
    return '';
}

/**
 *  filterMonth that can be selected year to year
 * @param selectedYear
 */
export function filterMonth(selectedYear: string): Month[] {
    if (selectedYear === getCurrentYear().toString()) {
        const currentMonth = new Date().getMonth() + 1;

        return AppConstants.monthsList
          .filter(o => parseInt(o.number, 10) >= currentMonth);
    }

    return AppConstants.monthsList;
}

/**
 * formatMonth(): string
 * format month 2 -> 02
 * */
export function formatMonth(m: number): string | number {
    return m < 10 ? '0' + m : m;
}

/**
 * formatYear(yyyy: any): string
 * format year 2018 -> 18
 * */
export function formatYear(yyyy: string): string {
    return ('' + yyyy).substring(2);
}

/**
 * getDevice(width: number): 'mobile' | 'tablet' | 'desktop'
 * width <= 767 = mobile
 * width > 767 && width <= 1024 = tablet
 * width > 1024 = desktop
 * */
export function getDevice(width: number): 'mobile' | 'tablet' | 'desktop' {
    if (width <= 767) {
        return 'mobile';
    } else if ((width > 767) && (width <= 1024)) {
        return 'tablet';
    }

    return 'desktop';
}

/**
 * Map Yext Location to Reducer interface
 *
 * @param {Location[]} data
 * @returns {Facility[]}
 *  A list of Facilities with type Facility
 */
export function mapYextResponseToReducer(data: Location[], distances: IDistance[] = []): FacilityYext[] {
  return data.map((entity, index) => new FacilityYext(entity, distances[index]));
}

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 *
 * @author inspired by [jhildenbiddle](https://stackoverflow.com/a/48218209).
 */
export function deepMergeObj(target: object, source: object): any {
  const isObject = (obj: object) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepMergeObj(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}


/**
 * Creates an object with arrays coming from loadFacilitiesByRegion action
 * @param {IFacilitiesStates} facilities
 */
export function getKeys(facilities: IFacilitiesStates): IFacilitiesStates {
  let obj = {};

  for (const key in facilities) {
    if (key && facilities && facilities[key]) {
      obj = {
        ...obj,
        ..._.keyBy(facilities[key], 'id'),
      };
    }
  }

  return obj;
}

/**
 * Get a list of invalid controls
 * @param {FormGroup} form
 *  FormGroup instance
 * @returns {string[]}
 *  A list of invalid fields
 */
export function findInvalidControls(form: FormGroup): string[] {
  const invalid = [];
  const controls = form.controls;

  for (const name in controls) {
    if (controls[name]?.invalid) {
      invalid.push(name);
    }
  }

  return invalid;
}

/**
 * Timeout for async/await functions
 * @param {number} ms
 *  delay in milliseconds
 * @returns Promise
 * @example
 *  async () => {
 *    // do something
 *    await sleep(1000);
 *    // do something else after 1000ms
 *  }
 */
export function sleep(ms: number): Promise<any> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Converts the query params object into a query string
 * @param {{ [key: string]: string }} queryParams
 *  Query Params object
 * @returns {string}
 *  Query string
 * @example
 *  objectToQueryString({ param1: 'test1', param2: 'test2' })
 *    -> 'param1=test1&param2=test2'
 */
export function objectToQueryString(queryParams: { [key: string]: string }): string {
  return Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
}

/**
 * Checks if it is a full or partial url
 * @param {string} item
 * @returns {boolean}
 *  Wether has 'http' or '//' or not
 */
export function isFullUrl(link: string): boolean {
  return link?.includes('http') || link?.includes('//');
}
