import { createAction, props } from '@ngrx/store';
import {
  FacilitiesStateActionTypes,
  IFacilitiesByRegionRequest,
  FacilitiesByCityActionTypes,
  IFacilitiesByCityRequest,
} from './facilities-by-region.actions.types';
import { IFacilitiesStates } from '../../../models';

/* Get Facilities By State */
/**
 * Load facilities by its `address.region` from Yext,
 * The state comes from constants list of states.
 *
 * @example
 *  // Without options
 *  this.store.dispatch(
 *    loadFacilitiesByRegion({ state: { abbreviation: 'FL', name: 'Florida' } }),
 *  );
 *
 *  // With options
 *  this.store.dispatch(
 *    loadFacilitiesByRegion({ state: { abbreviation: 'FL', name: 'Florida' }, options: { fields: ['mainPhone'] } }),
 *  );
 */
export const loadFacilitiesByRegion = createAction(
  FacilitiesStateActionTypes.request,
  props<IFacilitiesByRegionRequest>(),
);
export const loadFacilitiesByRegionSuccess = createAction(
  FacilitiesStateActionTypes.requestSuccess,
  props<{ facilities: IFacilitiesStates }>(),
);
export const loadFacilitiesByRegionFailure = createAction(
  FacilitiesStateActionTypes.requestFailure,
  props<{ error: any }>(),
);

/* Get Facilities By City */
/**
 * Load facilities by its `address.region` from Yext,
 * The state comes from constants list of states.
 *
 * @example
 *  // Without options
 *  this.store.dispatch(
 *    loadFacilitiesByRegion({ state: { abbreviation: 'FL', name: 'Florida' } }),
 *  );
 *
 *  // With options
 *  this.store.dispatch(
 *    loadFacilitiesByRegion({ state: { abbreviation: 'FL', name: 'Florida' }, options: { fields: ['mainPhone'] } }),
 *  );
 */
export const loadFacilitiesByCity = createAction(
  FacilitiesByCityActionTypes.request,
  props<IFacilitiesByCityRequest>(),
);
export const loadFacilitiesByCitySuccess = createAction(
  FacilitiesByCityActionTypes.requestSuccess,
  props<{ facilities: IFacilitiesStates }>(),
);
export const loadFacilitiesByCityFailure = createAction(
  FacilitiesByCityActionTypes.requestFailure,
  props<{ error: any }>(),
);
