<div class="row">
	<div class="col px-0">
		<div class="alert bg-green mb-0 text-center" role="alert">
			<span class="text-uppercase nimbussans_black">Blink Corona is now open!</span>
			<button type="button" class="close" data-dismiss="alert" aria-label="Close">
				<span aria-hidden="true">
					<i class="now-ui-icons cursor-pointer ui-1_simple-remove pt-2"></i>
				</span>
			</button>
		</div>
	</div>
</div>