import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as _ from 'lodash';
import {
  FacilitiesGeoSearchActionTypes,
  IFacilitiesByGeoSearchRequest,
} from '../../actions/facilities-by-geo/facilities-by-geo.actions.types';
import { YextService } from '../../../core/services/yext.service';
import { mapYextResponseToReducer } from '../../../core/helpers';

/**
 * Facilities By Geo Serach Effects
 */
@Injectable()
export class FacilitiesByGeoEffects {
  /**
   * Effect Get Facilities by Geo Search
   *
   * @description
   * This effect is triggered by Load Facilities by ID action.
   * It receives an id from facility `id`
   */
  getFacilityByGeoSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FacilitiesGeoSearchActionTypes.request),
      mergeMap((action: IFacilitiesByGeoSearchRequest) =>
        this.yextService.searchGeoLocation(action.location, action.options).pipe(
          map((data) => ({
            type: FacilitiesGeoSearchActionTypes.requestSuccess,
            facilities: mapYextResponseToReducer(data?.response?.entities, (data?.response as any)?.distances),
            search: action.location,
          })),
          catchError((error) =>
            of({
              type: FacilitiesGeoSearchActionTypes.requestFailure,
              error,
            }),
          ),
        ),
      ),
    ),
  );

  constructor(private actions$: Actions, private yextService: YextService) {}
}
