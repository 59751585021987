import { IFolder } from '../../../models';
import { IBaseAction, IRequestYextOptions } from '../common.types';

export enum FoldersActionTypes {
  request = '[Folders] Load',
  requestSuccess = '[Folders] Load Success',
  requestFailure = '[Folders] Load Failure',
}

export interface IFoldersAction extends IBaseAction {
  folders: IFolder[];
}

export type IFoldersRequest = IRequestYextOptions;
