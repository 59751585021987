import { createAction, props } from '@ngrx/store';
import { TermsOfUsePopupActionTypes } from './terms-of-use-popup.types';

export const loadTermsOfUsePopup = createAction(
    TermsOfUsePopupActionTypes.loadTermsOfUsePopup,
    props<{visible: boolean, content: string, title: string}>(),
);

export const destroyTermsOfUsePopup = createAction(
    TermsOfUsePopupActionTypes.destroyTermsOfUsePopup
);
