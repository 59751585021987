import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {LoggerService} from './log4ts/logger.service';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {IFacilities, IFacility, MosoUser} from '../../models';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {UserService} from './user.service';
import {LocalStorageService} from './helpers/local-storage.service';
import {AmplifyService} from 'aws-amplify-angular';
import {YextService} from './yext.service';
import {mapYextResponseToReducer} from '../helpers';

@Injectable()
export class CmsService {
    private userMoSo: MosoUser;
    private facilities: IFacilities = {};

    // private _referralsPageCms: BehaviorSubject<CmsPage> = new BehaviorSubject(null);
    // public readonly referralsPageCms$: Observable<CmsPage> = this._referralsPageCms.asObservable().pipe(distinctUntilChanged());

    private _locationDataCms: BehaviorSubject<IFacility> = new BehaviorSubject(null);
    public readonly locationDataCms$: Observable<IFacility> = this._locationDataCms.asObservable().pipe(distinctUntilChanged());

    private _terms: BehaviorSubject<any> = new BehaviorSubject(null);
    public readonly terms$: Observable<any> = this._terms.asObservable().pipe(distinctUntilChanged());

    private _privacy: BehaviorSubject<any> = new BehaviorSubject(null);
    public readonly privacy$: Observable<any> = this._privacy.asObservable().pipe(distinctUntilChanged());

    /**
     * CmsService constructor
     * @param {AmplifyService} amplifyService
     * @param {UserService} _userService
     * @param {LoggerService} logger
     * @param {YextService} yextService
     */
    constructor(
        public amplifyService: AmplifyService,
        private _userService: UserService,
        private logger: LoggerService,
        private yextService: YextService,
    ) {
        this.logger.info('-- CmsService init --');
        this._userService.userDataMoso$.subscribe((userMoSo: MosoUser) => {
                if (userMoSo) {
                    this.userMoSo = userMoSo;
                    this.getMemberGymLocationDataById(this.userMoSo.Location.Code);
                }
            }
        );
        this.setFacilitiesToLocalStorage();
    }


    ////////////////////////////////////New Refactoring Here////////////////////////////////////////////////////

    getMemberGymLocationDataById(facilityId: string): any {
        this.facilities = JSON.parse(localStorage.getItem('facilities'));

        if (facilityId && this.facilities) {
          this._locationDataCms.next(this.facilities[facilityId]);
        }
    }

    setTerms(data: any) {
        this._terms.next(data);
    }

    setPrivacy(data: any) {
        this._privacy.next(data);
    }

    /**
     * Getting CMS data
     * */
    getPageDataBySlug(slug: string): Observable<any> {
        return from(this.amplifyService.api().get('iBlinkCMSAPI', `/pages/slug/${slug}`, {}));
    }


    /**
     * Getting CMS data by Slug
     * @param slug
     */
    getGlobalPageDataBySlug(slug: string): Observable<any> {
        return from(this.amplifyService.api().get('iBlinkCMSAPI', `/global/pages/slug/${slug}`, {}));
    }

    /**
     * get Facilities from Global CMS
     */
    getGlobalFacilitiesData() {
        return from(this.amplifyService.api().get('iBlinkCMSAPI', `/global/facilities`, {
            queryStringParameters: {
                per_page: '200'
            }
        }));
    }

    /**
     * get Global CMS Club Terms By ID
     * @param id:number
     * @returns {Observable<FacilitiesGlobal[]>}
     */
    getGlobalClubTermsDataByID(id: string) {
        return from(this.amplifyService.api().get('iBlinkCMSAPI', `/global/club-terms/${id}`, {}));
    }

    /**
     * get Global CMS dataSet by slug
     * @param slug: string
     * @returns {Observable<FacilitiesGlobal[]>}
     */
    getGlobalDataSetBySlug(slug: string) {
        return from(this.amplifyService.api().get('iBlinkCMSAPI', `/global/data-sets/slug/${slug}`, {}));
    }

     /**
     * get iBlink CMS dataSet by slug
     * @param slug: string
     * @returns {Observable<FacilitiesGlobal[]>}
     */
    getDataSetBySlug(slug: string) {
        return from(this.amplifyService.api().get('iBlinkCMSAPI', `/data-sets/slug/${slug}`, {}));
    }



    getAllFacilityAreasGlobal(): Observable<any> {
        return from(this.amplifyService.api().get('iBlinkCMSAPI', `/global/facility-areas`, {
            queryStringParameters: {
                per_page: '200'
            }
        }));
    }

    setFacilitiesToLocalStorage(): void {
        const facilities: IFacilities = JSON.parse(localStorage.getItem('facilities'));

        if (facilities) {
          return;
        }

        from(this.yextService.getAllFacilities({
          fields: ['c_terms_slug'],
        }))
          .pipe(
            map((data) => _.keyBy(
              mapYextResponseToReducer(data?.response?.entities),
              'id',
            ))
          ).subscribe((facilities: IFacilities) => {
            LocalStorageService.setLocaStorage('facilities', facilities);
          });
    }

  /**
   * Get Term of use, Privacy policy, PT Terms and Conditions, and others
   * from CMS by its slug.
   * The slug is found on Yext club data;
   *
   * @param {string} slug
   * @returns {Observable<any>}
   *  Observable Stream with page data
   */
  getTermsBySlug(slug: string): Observable<any> {
    if (!slug) {
      return;
    }

    return from(
      this.amplifyService
        .api()
        .get('globalCMSAPI', '/global/club-terms', {
          queryStringParameters: { slug },
        })
    );
  }
}
