import { createAction, props } from '@ngrx/store';
import {
  IPrivacyPolicyRequest,
  PrivacyPolicyActionTypes,
} from './privacy-policy.actions.types';
import { IPrivacyPolicyState } from '../../reducers/privacy-policy/privacy-policy.reducer.types';


export const loadPrivacyPolicy = createAction(
  PrivacyPolicyActionTypes.request,
  props<IPrivacyPolicyRequest>(),
);

export const loadPrivacyPolicySuccess = createAction(
  PrivacyPolicyActionTypes.requestSuccess,
  props<{ data: IPrivacyPolicyState }>(),
);

export const loadPrivacyPolicyFailure = createAction(
  PrivacyPolicyActionTypes.requestFailure,
  props<{ error: any }>(),
);
