import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {LoggerService} from '../log4ts/logger.service';

@Injectable()
export class PopupService {
    public _showCreditCardPopUp: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showCreditCardPopUp: Observable<boolean> = this._showCreditCardPopUp.asObservable();

    public _showPopUp: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showPopUp: Observable<boolean> = this._showPopUp.asObservable();

    /**
     * PopupService constructor
     * @param {Router} router
     * @param {LoggerService} logger
     */
    constructor(private router: Router, private logger: LoggerService) {
        this.logger.info('-- PopupService init --');
    }

    /**
     * setPopupVisible
     * @param val
     */
    setPopupVisible(val: any): void {
        if ((<any>this.router.url) === '/iblink/billing') {
            this._showCreditCardPopUp.next(val);
        } else {
            this.router.navigate(['/iblink/billing']);
            this._showCreditCardPopUp.next(val);
        }
    }

    setPopUpState(val: boolean): void {
        this._showPopUp.next(val);
    }

}
