/**
 * Referral
 * */
export class Referral implements IReferral {
    referralID: string;
    referralMosoID: number;
    referralFirstName: string;
    referralLastName: string;
    referralEmail: string;
    referralCreatedDate: Date;
    matchedDate: Date;
    referralStatusID: string;
    referralStatusTitle: string;
    referralStatusTitleName: string;
    rewardPrice: string | number;
    rewardPriceGettingDate?: Date;
    rewardExpirationDate?: Date;
    RefereeCognitoID: string;
    RefereeMoSoID: number;

    constructor() {
    }
}

export interface IReferral {
    referralID: string;
    referralMosoID: number;
    referralFirstName: string;
    referralLastName: string;
    referralEmail: string;
    referralCreatedDate: Date;
    matchedDate: Date;
    referralStatusID: string;
    referralStatusTitle: string;
    referralStatusTitleName: string;
    rewardPrice: string | number;
    rewardPriceGettingDate?: Date;
    rewardExpirationDate?: Date;
    RefereeCognitoID: string;
    RefereeMoSoID: number;
}


