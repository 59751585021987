<div class="row" *ngIf="visible">
	<div class="modal fade" id="universal-popup" tabindex="-1" role="dialog" aria-labelledby="terms-and-conditions-popup" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close cursor-pointer" data-dismiss="modal" aria-hidden="true">&times;</button>
					<h3 class="modal-title">{{title}}</h3>
				</div>
				<div class="modal-body pt-0">
					<div [innerHTML]="content"></div>
				</div>
			</div>
		</div>
	</div>
</div>
