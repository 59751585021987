import {AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {constants} from '../../const/constants';
import {UserService} from '../../services/user.service';
import {Observable, Subscription} from 'rxjs';
import {FacadeService} from '../../services/core/facade.service';
import {map} from 'rxjs/operators';
import {CustomWPpageContent} from '../../../models';


@Component({
    selector: 'ib-main-layout',
    templateUrl: './main-layout.component.html',
    encapsulation: ViewEncapsulation.None
})
export class MainLayoutComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {
    public headerMobileMenu: any;
    public leftSidebarVisible: boolean;
    cmsData: CustomWPpageContent;
    subscription: Subscription;
    subscriptionCms: Subscription;
    isMobile: boolean;
    isClosed = false;

    constructor(public userService: UserService, private _facadeService: FacadeService) {
    }


    onCloseBanner() {
        this.isClosed = true;
    }

    ngOnInit() {
        this.headerMobileMenu = constants.headerMobileMenusItems;
        if (window.screen.width < 768) {
            this.userService.setLeftSidebarVisible(false);
        }
        this.subscription = this.userService.leftSidebarVisible.subscribe(data => {
            this.leftSidebarVisible = data;
        });

        this.subscriptionCms = this._facadeService.getPageDataBySlug('iblink-home-page').pipe(map(res => res.acf)).subscribe((data) => {
            if (data) {
                this.cmsData = data;
            }
        });

        this.isMobile = this.checkMobile();

    }

    checkMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.screen.width < 768;
    }

    ngAfterViewInit() {
    }

    ngAfterViewChecked() {
    }


    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subscriptionCms.unsubscribe();
    }

}



