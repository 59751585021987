import {Injectable} from '@angular/core';
import {LoggerService} from '../log4ts/logger.service';
import {AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';
import 'amazon-cognito-js';
import * as _ from 'lodash';
import {environment} from '../../../../environments/environment';

declare let $: any;


@Injectable()
export class AwsService {
    public static _POOL_DATA: any = {
        UserPoolId: environment.awsConfig.UserPoolId,
        ClientId: environment.awsConfig.ClientId,
    };

    cognitoUser: CognitoUser;
    cognitoSession: CognitoUserSession;
    idenityCredentials: AWS.CognitoIdentityCredentials;
    idenityPermissions: Promise<any>;

    constructor(public logger: LoggerService) {
        this.logger.info('-- AwsService init --');
        AWS.config.region = environment.awsConfig.region;
    }


    /**
     * Getting Cognito Current User
     * */
    getCurrentCognitoUser(): CognitoUser | null {
        return this.getCognitoUserPool().getCurrentUser();
    }

    /**
     * Getting Cognito User Pool
     * */
    getCognitoUserPool(): CognitoUserPool | null {
        return new CognitoUserPool(AwsService._POOL_DATA);
    }

    confirmPassword(confirmation_code: string, password: string) {
        const self = this;
        return new Promise((resolve, reject) => {
            this.cognitoUser.confirmPassword(confirmation_code, password, {
                onSuccess() {
                    resolve(true);
                },
                onFailure(err) {
                    self.logger.error('New Password didn\'t confirmed for new iBlink! Please try again.', err);
                    reject(err);
                }
            });
        });
    }


    /**
     * Congnito User Get Current user status
     * */
    isLoggedIn(): Promise<boolean> {
        const cognitoUser = this.getCurrentCognitoUser();
        return new Promise((resolve, reject) => {
            if (cognitoUser != null) {
                cognitoUser.getSession((err: any, session: any) => {
                    if (err) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Identity Credentials
     * */
    checkIdentityCredentials(cb = null): void {
        this.cognitoUser = this.getCurrentCognitoUser();
        if (this.cognitoUser !== null) {
            this.getCognitoSession()
                .then((session: CognitoUserSession) => {
                    this.cognitoSession = session;
                    return this.setIdentityCredentials();
                })
                .then(() => {
                    return this.idenityPermissions = this.getIdenityCredentials();
                })
                .then(() => {
                    this.setIdentityDataSet();
                    if (cb) {
                        cb();
                    }
                })
                .catch(error => this.logger.error(error));
        } else {
            this.setUnauthIdentityCredentials();
        }
    }


    /**
     * Get Congnito Cognito Session
     * */
    getCognitoSession() {
        if (this.cognitoUser !== null) {
            return new Promise((resolve, reject) => {
                this.cognitoUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });
            });
        }
        return null;
    }

    /**
     * Get Idenity Credentials
     * */
    getIdenityCredentials(): Promise<any> {
        if (this.cognitoSession.isValid()) {
            return new Promise(resolve => {
                this.idenityCredentials.get(() => {
                    const permissions = {
                        identityId: this.idenityCredentials.identityId,
                        accessKeyId: this.idenityCredentials.accessKeyId,
                        secretAccessKey: this.idenityCredentials.secretAccessKey,
                        sessionToken: this.idenityCredentials.sessionToken
                    };
                    resolve(permissions);
                });
            });
        }
    }

    /**
     * Set Identity Credentials
     * */
    setIdentityCredentials(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.cognitoSession.isValid()) {
                const aws_login = 'cognito-idp.' + environment.awsConfig.UserPoolIdRegion + '.amazonaws.com/' + environment.awsConfig.UserPoolId;
                const linked_logins = {};
                linked_logins[aws_login] = this.cognitoSession.getIdToken().getJwtToken();
                AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: environment.awsConfig.IdentityPoolId, // your identity pool id here
                    Logins: linked_logins
                });
                this.idenityCredentials = <AWS.CognitoIdentityCredentials>AWS.config.credentials;
                resolve(true);
            }
            reject('Cognito Session is inValid');
        });
    }

    /**
     * verifyUserEmail(email)
     * **/
    //TODO: please updata region
    verifyUserEmail(email: string): void {
        this.idenityPermissions.then(res => {
            const client_params = {
                apiVersion: '2016-04-19',
                region: 'us-east-1' // change region if required
            };
            const client = new AWS.CognitoIdentityServiceProvider(<any>client_params);
            client.adminUpdateUserAttributes(
                {
                    UserAttributes: [
                        {
                            Name: 'email_verified',
                            Value: 'true'
                        }
                    ],
                    UserPoolId: environment.awsConfig.UserPoolId,
                    Username: email
                },
                err => {
                    if (err) {
                        this.logger.error(err, err.stack);
                    } else {
                    }
                }
            );
        });
    }

    getCurrentIdentityDataSetState(dataset: any): Promise<any> {
        return new Promise((resolve) => {
            dataset.synchronize({
                onSuccess: (data, newRecords) => {
                    resolve(true);
                }
            });
        });
    }

    /**
     * getDataSetData
     * @param {string} itemName
     * @returns {Promise<any>}
     */
    getDataSetData(itemName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.idenityCredentials.identityId) {
                const syncClient = new (<any>AWS).CognitoSyncManager();
                syncClient.openOrCreateDataset('iBlinkMember', (err, dataset) => {
                    if (!err) {
                        dataset.get(itemName, (error, value) => {
                            if (error) {
                                reject(error);
                            } else {
                                if (value && value !== '') {
                                    resolve(value);
                                } else {
                                    reject(value);
                                }
                            }
                        });
                    } else {
                        resolve('Cannot get syncClient.openOrCreateDataset');
                        this.logger.error('Cannot get syncClient.openOrCreateDataset');
                    }

                });
            }
        });
    }

    /**
     * setDataSetData
     * @param {string} itemName
     * @param {string} val
     * @returns {Promise<any>}
     */
    setDataSetData(itemName: string, val: string): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.idenityCredentials.identityId) {
                const syncClient = new (<any>AWS).CognitoSyncManager();
                syncClient.openOrCreateDataset('iBlinkMember', (err, dataset) => {
                    if (!err) {
                        dataset.put(itemName, val, (error, value) => {
                            if (value) {
                                resolve(value);
                            }
                            if (error) {
                                reject(error);
                            }
                        });
                    } else {
                        reject('Cannot get syncClient.openOrCreateDataset');
                        this.logger.error('Cannot get syncClient.openOrCreateDataset');
                    }

                });
            }
        });
    }

    /**
     * Set Idenity Data Set
     * */
    setIdentityDataSet(): void {
        const self = this;
        if (this.idenityCredentials.identityId) {
            const syncClient = new (<any>AWS).CognitoSyncManager();
            const verifyUserEmail = this.verifyUserEmail;
            this.getCognitoUserAttributes()
                .then(attrs => {
                    const email = attrs['email'];
                    const MoSoID = attrs['custom:moso_member_id'];
                    const identityId = attrs['sub'];
                    syncClient.openOrCreateDataset('iBlinkMember', (err, dataset) => {
                        this.getCurrentIdentityDataSetState(dataset)
                            .then(() => {
                                dataset.put('UserPoolId', identityId, () => {
                                });
                                dataset.put('MoSoID', MoSoID, () => {
                                });
                                dataset.put('email', email, () => {
                                });
                                dataset.synchronize({
                                    onSuccess: (data, newRecords) => {
                                        //verifyUserEmail(email);
                                    }
                                });

                            });

                    });
                })
                .catch(error => {
                    this.logger.error(error);
                });
        }
    }

    /**
     * Get Cognito User Attributes
     * */
    getCognitoUserAttributes(): Promise<any> {
        const userFormated: any = {};
        return new Promise((resolve, reject) => {
            if (this.cognitoUser !== null) {
                this.cognitoUser.getSession((err, session) => {
                    if (err) {
                        this.logger.error('getSession -> getCognitoUserAttributes', err);
                        return;
                    }
                    if (session.isValid()) {
                        this.cognitoUser.getUserAttributes((error, result) => {
                            if (error) {
                                reject(err);
                            } else {
                                _.forEach(result, (value, key) => {
                                    const prop: any = value.getName();
                                    userFormated[prop] = value.getValue();
                                });
                                resolve(userFormated);
                            }
                        });
                    }
                });
            } else {
                reject('Cognito User not authorized');
            }
        });
    }

    /**
     * Set Identity Credentials
     * */
    setUnauthIdentityCredentials(): Promise<any> {
        return new Promise((resolve, reject) => {
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: environment.awsConfig.IdentityPoolId, // your identity pool id here
                Logins: {}
            });
            this.idenityCredentials = <AWS.CognitoIdentityCredentials>AWS.config.credentials;
            resolve(true);
        });
    }

    sendContactUsEmail(message, snsTopic) {
        const sns = new AWS.SNS({region: environment.awsConfig.APIregion});
        return sns.publish({
            Message: JSON.stringify({
                'default': JSON.stringify(message)
            }),
            MessageStructure: 'json',
            TopicArn: snsTopic
        }).promise();
    }

    /**
     * authenticate User In Cognito
     * @param {string} oldPassword
     * @param {"amazon-cognito-identity-js".CognitoUser} cognitoUser
     * @returns {Promise<"amazon-cognito-identity-js".CognitoUserSession | any>}
     */
    authenticateUser(oldPassword: string, cognitoUser: CognitoUser): Promise<CognitoUserSession | any> {
        const authData = {Username: cognitoUser.getUsername(), Password: oldPassword};
        const authDetails = new AuthenticationDetails(authData);
        return new Promise((resolve, reject) => {
            cognitoUser.authenticateUser(authDetails, {
                onSuccess: result => resolve(result),
                onFailure: error => reject(error)
            });
        });
    }

}
