/**
 * Agreement Data Model (Agreement)
 * */
export class InvoiceItem implements IInvoiceItem {
    Id: number;
    Name: string;
    Code: string;
    Description: string;
    ItemType: string;
    Category: Category;
    SubCategory: string;
    UPC: string;
    RevenueGLCode: string;
    RevenueGLName: string;
    RecurringGLCode: string;
    RecurringGLName: string;
    DeferredGLCode: string;
    DeferredGLName: string;
    AvailableAtPOS: boolean;
    AvailableForRecurring: boolean;
    MemberRequired: boolean;
    QualifiedMemberRequired: boolean;
    Price: number;
    QualifiedMemberPrice: number;
    ServiceItemDetails: string;
    FeeItemDetails: string | number;
    AccessItemDetails: string;

    constructor() {
    }
}

export interface IInvoiceItem {
    Id: number;
    Name: string;
    Code: string;
    Description: string;
    ItemType: string;
    Category: Category;
    SubCategory: string;
    UPC: string;
    RevenueGLCode: string;
    RevenueGLName: string;
    RecurringGLCode: string;
    RecurringGLName: string;
    DeferredGLCode: string;
    DeferredGLName: string;
    AvailableAtPOS: boolean;
    AvailableForRecurring: boolean;
    MemberRequired: boolean;
    QualifiedMemberRequired: boolean;
    Price: number;
    QualifiedMemberPrice: number;
    ServiceItemDetails: string;
    FeeItemDetails: string | number;
    AccessItemDetails: string;
}

export interface Category {
    Id: number;
    Name: string;
    DisplayColor: string;
    ParentCategoryId: number;
}








