import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { LoggerService } from '../services/log4ts/logger.service';
import { Observable, Subscription } from 'rxjs';
import { browserRefresh } from '../services/analytics.service';


@Injectable()
export class DoNotShowOnRefreshGuard implements CanActivate, CanActivateChild {
    subscription: Subscription;

    constructor(private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | Promise<boolean> {
        if (!browserRefresh) {
            return true;
        } else {
            this.router.navigate(['/']);
            return false;
        }
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

}
