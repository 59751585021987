<div class="row" *ngIf="user">
	<div class="col-12 bg-green text-center confetti">
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		<h1 class="text-white text-uppercase mt-3 mb-0  nimbussans_black">you're good to go,</h1>
		<h1 class="text-white text-uppercase mt-3 mb-0  nimbussans_black">{{user.givenName}}</h1>
		<br>
		<p class="nimbussans_bold">Schedule your training sessions with a Mood Lifter<sup>TM</sup> at the front desk the next time you're at Blink.</p>
		<br>
		<br>
		<div class="d-none d-md-block">
			<a class="btn btn-grey text-uppercase nimbussans_black btn-with-icon" routerLink="/iblink/startup">take me to blink <i class="fa fa-chevron-right"></i></a>
		</div>
		<div class="d-md-none">
			<a class="btn btn-grey text-uppercase nimbussans_black" routerLink="/iblink/startup">take me to blink</a>
		</div>
		<br>
		<br>
		<br>
		<br>
		<br>
		<br>
		</div>
</div>