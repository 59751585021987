import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ModalComponent} from '../modal/modal.component';

@Component({
    selector: 'ib-error-modal',
    templateUrl: './error-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ErrorModalComponent {

    @Output('onReady') onReady = new EventEmitter<ErrorModalComponent>();
    @Input('message') message;

}
