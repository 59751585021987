import { environment } from '../../../../environments/environment';

const defaultConfiguration = {
  apiKey: environment.API_CONF.YEXT_KEY,
  apiVersion: '20200320',
  accountId: 'me',
  retryCount: 3,
  rateLimitRetry: false,
};
export const YextLiveAPI = {
  ...defaultConfiguration,
  baseUrl: 'https://liveapi.yext.com/v2/accounts',
};
export const YextKnowledgeAPI = {
  ...defaultConfiguration,
  baseUrl: 'https://api.yext.com/v2/accounts',
};

export const defaultFields = [
  'address',
  'description',
  'emails',
  'name',
  'meta',
  'geomodifier',
  'c_clubAmenities',
  'c_clubUniqueIdentifierSlug',
  'c_pricingDisclaimer1',
  'c_pricingDisclaimer2',
  'c_pricingDisclaimer3',
  'c_pricingDisclaimer4',
  'c_terms_slug',
  'c_clubType',
  'c_status',
  'c_pagesURL',
  'c_agreementStartDate',
  'hours',
  'mainPhone',
];
