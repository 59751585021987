import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {PopupService} from '../../services/helpers/popup.service';
import {ToastService} from '../../services/helpers/toast.service';
import {LocalStorageService} from '../../services/helpers/local-storage.service';
import {CognitoUserModel, MosoUser} from '../../../models';
import {AwsAmplifyService} from '../../services/core/aws-amplify.service';
import {FacadeService} from '../../services/core/facade.service';
import {cleanSubscriptions} from '../../helpers';
import * as _ from 'lodash';

@Component({
    selector: 'ib-top-header-navigation',
    templateUrl: './top-header-navigation.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TopHeaderNavigationComponent implements OnInit, OnDestroy {
    @Input() options: any;
    user$: Observable<CognitoUserModel>;
    userMOSOData: MosoUser;
    subscriptions: Array<Subscription> = [];
    balance: any;
    balancePos: string;
    isPastDate: boolean;
    isPaymentLocked: boolean;
    isFirstDate: boolean;
    isFirstToast: boolean;
    paymentRequiredModalActive: boolean;

    constructor(private _facadeService: FacadeService,
                public popupService: PopupService,
                public _awsAmplifyService: AwsAmplifyService,
                public toastService: ToastService) {
    }

    /*** ngOnInit **/
    ngOnInit(): void {
        this.getModalSettings().then(this.onGetModalSettings.bind(this));
        this.isFirstDate = this._facadeService.isFirstDate();
    }

    onGetModalSettings(data: any) {
        this.paymentRequiredModalActive = _.get(data, 'acf.the_payment_required_modal.active');
        this.getUserData();
    }

    getModalSettings(): Promise<any> {
        return this._facadeService.getDataSetBySlug('general-modal-settings').toPromise();
    }

    /*** Get User Data **/
    getUserData(): void {
        this.user$ = this._facadeService.getCognitoUserDataOb();

        this.subscriptions.push(
            this._facadeService.getMosoUserDataOb()
                .subscribe(this.onUserDataChange.bind(this))
        );
    }

    onUserDataChange(data: MosoUser) {
        if (data) {
            const balanceShowed = LocalStorageService.getLocalStorage('first:show');
            const balanceCheck = data.Balance > 0 && !this.isFirstDate;
            this.userMOSOData = data;

            if (this.userMOSOData.Balance || this.userMOSOData.Balance === 0) {
                this.balance = this.userMOSOData.Balance.toFixed(2);
            }
            if (data.Balance < 0) {
                this.balancePos = (Number(this.userMOSOData.Balance) * -1).toFixed(2);
            }

            this.isPastDate = (this.userMOSOData.AccountStatus === 'Past Due') && (this.balance > 0);

            if (!this.isPaymentLocked && (balanceCheck && !balanceShowed)) {
                this.openPaymentModal();
            }
        }
    }

    openPaymentModal() {
        LocalStorageService.setLocaStorage('first:show', true);
        this.toastService.setToastVisible({
            visible: true,
            options: {
                modalTitle: 'Payment Required',
                modalMessage: `
                    <p>
                        Your Blink account is now in default.<br>
                        Pay your balance today so we can inform the Collection Agency that your account is up to date.<br>
                        Just click the <b>pay balance</b> button on the screen below.
                    </p>`,
                modalButtonTitle: 'Got it',
                modalClass: 'payment-pop-up pay-required-pop',
                modalButtonClass: 'btn btn-blue text-capitalize nimbussans_bold pull-right'
            }
        });
    }

    /*** Log Out User From AWS Cognito, Redirect User To Home Page **/
    logOut(): void {
        this._awsAmplifyService.signOut();
    }

    /*** ngOnDestroy **/
    ngOnDestroy(): void {
        this.clean();
    }

    /*** clear subscription array **/
    clean(): void {
        cleanSubscriptions(this.subscriptions);
    }

    openPopup() {
        if (Number(this.balance) > 0 && !this.isFirstDate) {
            this.popupService.setPopupVisible(true);
        }
    }

}
