
import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {LoggerService} from '../log4ts/logger.service';
import {SpinnerService} from '../../../shared/spinner/spinner.service';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
    contador = 0;

    constructor(private spinnerService: SpinnerService, public logger: LoggerService) {
        // this.spinnerService.show();
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.contador++;

        const _req = req.clone();
        return next.handle(_req).pipe(
            finalize(() => {
                this.contador--;
                if (this.contador === 0) {
                    this.spinnerService.hide();
                }
            })
        );
    }

}





