<div class="row">
	<div class="col-12 px-0">
		<header>
			<nav class="navbar navbar-expand-lg bg-white mb-0 p-0">
				<div class="col-6 px-0">
					<ib-navbar></ib-navbar>
					<a routerLink="/iblink/info">
						<img class="pl-4 pl-sm-0 pr-sm-0 pt-2" src="./assets/img/iblink_logo.svg" width="130" alt="">
					</a>
				</div>
				<div class="col-6 px-0">
					<ib-top-header-navigation [options]="options"></ib-top-header-navigation>
				</div>
			</nav>
		</header>
	</div>
</div>
