/**
 * General id
 * */
export class PopupOptions implements IPopupOptions {
    //buttonTitle?: string;
    //buttonColor?: string;
    modalTitle?: string;
    modalMessage?: string | any;
    modalButtonTitle?: string;
    modalClass?: string;
    //modalButtonColor?: string;
    //closeModalOnClickButton?: string | any;
    constructor() {
    }
}

export interface IPopupOptions {
    buttonTitle?: string;
    buttonColor?: string;
    modalTitle?: string;
    modalTitleClass?: string;
    modalMessage?: string | any;
    modalButtonTitle?: string;
    modalButtonColor?: string;
    closeModalOnClickButton?: string | any;
}

/* this.optionsPopup = {
     modalTitle: '',
     modalMessage: 'Looks like there was an error. Take a deep breath and try again?',
     modalButtonTitle: 'ok',
     modalClass: 'payment-pop-up',
     modalButtonClass: 'btn btn-orange text-uppercase nimbussans_bold pull-right'
 };*/
