import {Injectable, Injector} from '@angular/core';
import {LoggerService} from '../log4ts/logger.service';
import {CmsService} from '../cms.service';
import {UserService} from '../user.service';
import {AwsAmplifyService} from './aws-amplify.service';
import {AwsService} from './aws.service';
import {PtService} from '../pt.service';
import {PaymentService} from '../payment.service';
import {ReferralService} from '../referral.service';
import {CognitoUserModel, CreditCardUpdate, IFacilities, MemberActivity, MosoUser, NewReferral, PayBalanceData, PTPurchaseData} from '../../../models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BriteverifyService} from '../briteverify.service';

@Injectable()
export class FacadeService {
    referralServiceInitialized: any;

    /** Getting CMS Service **/
    private _cmsService: CmsService;
    public get cmsService(): CmsService {
        if (!this._cmsService) {
            this._cmsService = this.injector.get(CmsService);
        }
        return this._cmsService;
    }

    /** Getting UserService **/
    private _userService: UserService;
    public get userService(): UserService {
        if (!this._userService) {
            this._userService = this.injector.get(UserService);
        }
        return this._userService;
    }

    /** Getting awsAmplify Service **/
    private _awsAmplifyService: AwsAmplifyService;
    public get awsAmplifyService(): AwsAmplifyService {
        if (!this._awsAmplifyService) {
            this._awsAmplifyService = this.injector.get(AwsAmplifyService);
        }
        return this._awsAmplifyService;
    }

    /** Getting Aws Service **/
    private _awsService: AwsService;
    public get awsService(): AwsService {
        if (!this._awsService) {
            this._awsService = this.injector.get(AwsService);
        }
        return this._awsService;
    }

    /** Getting PtService Service **/
    private _ptService: PtService;
    public get ptService(): PtService {
        if (!this._ptService) {
            this._ptService = this.injector.get(PtService);
        }
        return this._ptService;
    }

    /** Getting Payment Service **/
    private _paymentService: PaymentService;
    public get paymentService(): PaymentService {
        if (!this._paymentService) {
            this._paymentService = this.injector.get(PaymentService);
        }
        return this._paymentService;
    }

    /** Getting Payment Service **/
    private _referralService: ReferralService;
    public get referralService(): ReferralService {
        if (!this._referralService) {
            this._referralService = this.injector.get(ReferralService);
        }
        return this._referralService;
    }

    /** Briteverify Service **/
    private _briteverifyService: BriteverifyService;
    public get briteverifyService(): BriteverifyService {
        if (!this._briteverifyService) {
            this._briteverifyService = this.injector.get(BriteverifyService);
        }
        return this._briteverifyService;
    }

    constructor(private injector: Injector, private logger: LoggerService) {
        this.logger.info('-- FacadeService init --');
        this.init();
    }

    init(): void {

    }


////////////////////////// CmsService /////////////////////////////////////////////////
    /**
     * Get CMS page data by slug
     */
    getPageDataBySlug(slug: string): Observable<any> {
        return this.cmsService.getPageDataBySlug(slug).pipe(map(data => data[0]));
    }

    /**
     * Get Global CMS data by slug
     *  @param slug: string
     */
    getGlobalPageDataBySlug(slug: string): Observable<any> {
        return this.cmsService.getGlobalPageDataBySlug(slug).pipe(map(nav => nav[0]));
    }

    /**
     * Get Global CMS Temrs data by ID
     *  @param id: number
     */
    getGlobalClubTermsDataByID(id: string): Observable<any> {
        return this.cmsService.getTermsBySlug(id);
    }

    /**
     * Get All Facilities from Global CMS
     */
    getGlobalFacilitiesData(): Observable<any> {
        return this.cmsService.getGlobalFacilitiesData();
    }

    setFacilitiesToLocalStorage(): void {
        this.cmsService.setFacilitiesToLocalStorage();
    }

    /**
     * get Global CMS dataSet by slug
     * @param slug: string
     */
    getGlobalDataSetBySlug(slug: string): Observable<any> {
        return this.cmsService.getGlobalDataSetBySlug(slug).pipe(map(nav => nav[0]));
    }

    /**
     * get CMS dataSet by slug
     * @param slug: string
     */
    getDataSetBySlug(slug: string): Observable<any> {
        return this.cmsService.getDataSetBySlug(slug).pipe(map(nav => nav[0]));
    }

    getTerms$Ob(): Observable<any> {
        return this.cmsService.terms$;
    }

    getPrivacy$$Ob(): Observable<any> {
        return this.cmsService.privacy$;
    }


    /**
     * Set Terms and Conditions data into Stream
     * @param data
     */
    setTerms(data): void {
        this.cmsService.setTerms(data);
    }

    /**
     * Set Terms and Conditions data into Stream
     * * @param data
     */
    setPrivacy(data): void {
        this.cmsService.setPrivacy(data);
    }

    /**-- BriteverifyService methods ends --**/
    validateEmailBriteverify(email: string): Observable<any> {
        return this.briteverifyService.validateEmailBriteverify(email);
    }
    /**-- BriteverifyService methods ends --**/


////////////////////////// User & Amplify & AWS services  /////////////////////////////////////////////////
    getCognitoUserDataOb(): Observable<CognitoUserModel | null> {
        return this.userService.user$;
    }

    getMosoUserDataOb(): Observable<MosoUser | null> {
        return this.userService.userDataMoso$;
    }

    setCognitoUserDataOb(): Observable<CognitoUserModel | null> {
        return this.userService._user;
    }

    setMosoUserDataOb(): Observable<MosoUser | null> {
        return this.userService._userDataMoso;
    }

    isAuthenticated(): Observable<boolean> {
        return this.awsAmplifyService.isAuthenticated();
    }

    signIn(email: string, password: string): Observable<boolean> {
        return this.awsAmplifyService.signIn(email, password);
    }

    forgotPasswordSubmit(username, data: { verificationCode: string, password: string, confirmPassword: string }): Observable<any> {
        return this.awsAmplifyService.forgotPasswordSubmit(username, data);
    }

    forgotPassword(username): Observable<boolean> {
        return this.awsAmplifyService.forgotPassword(username);
    }

    signInP(email: string, password: string): Promise<any> {
        return this.awsAmplifyService.signInP(email, password);
    }

    changePassword(oldPassword: string, newPassword: string): Promise<any> {
        return this.awsAmplifyService.changePassword(oldPassword, newPassword);
    }

    signOut(): void {
        return this.awsAmplifyService.signOut();
    }

    currentAuthenticatedUserP(): Promise<any> {
        return this.awsAmplifyService.currentAuthenticatedUserP();
    }

    getMosoUserP(id: string): Promise<MosoUser> {
        return this.awsAmplifyService.getMosoUserP(id);
    }

    checkIdentityCredentials(cb?: any): void {
        this.awsService.checkIdentityCredentials(cb);
    }

    verifyUserEmail(email: string): void {
        this.awsService.verifyUserEmail(email);
    }

    getDataSetData(itemName: string): Promise<any> {
        return this.awsService.getDataSetData(itemName as string);
    }

    setDataSetData(itemName: string, val: string): Promise<any> {
        return this.awsService.setDataSetData(itemName as string, val as string);
    }

    signUpP(registerData): Promise<any> {
        return this.awsAmplifyService.signUpP(registerData);
    }

    getCognitoMemberById(id: string): Promise<MosoUser> {
        return this.awsAmplifyService.getCognitoMemberById(id);
    }


    updateUserInformation(userData: MosoUser): Observable<MosoUser> {
        return this.userService.updateMosoUserInfo(userData);
    }

    setLeftSidebarVisible(state): void {
        this.userService.setLeftSidebarVisible(state);
    }

    setMosoUserData(userData: MosoUser): void {
        this.userService.setMosoUserData(userData);
    }

    refreshMosoUserData(RoleId: string): void {
        this.userService.getUserDataMOSO(RoleId);
    }

    /**
     * Gets moso user by its Moso ID
     * @param {string} memberId
     * @returns {Observable<MosoUser>}
     */
    getMosoUserByMosoId(memberId: string) {
        return this.userService.getMosoUserByMosoId(memberId);
    }

    requestPtSession(data: any): Observable<MosoUser> {
        return this.userService.requestPtSession(data);
    }

    getCurrentUserAttrP(cognitoUser: any): Promise<any> {
        return this.awsAmplifyService.getCurrentUserAttrP(cognitoUser);
    }

    refreshCognitoUserData(): void {
        this.userService.getCognitoUserData();
    }

    isFirstDate(): boolean {
        return this.userService.isFirstDate();
    }

////////////////////////// PtService & Payment services  /////////////////////////////////////////////////
    purchasePTPlan(purchaseData: PTPurchaseData): Observable<any> {
        return this.ptService.purchasePTPlan(purchaseData);
    }

    getMemberMonthlyPTAgreements(): Promise<MemberActivity[]> {
        return this.ptService.getMemberMonthlyPTAgreements();
    }

    getCheckIns(dates, memberid, limit, page): Observable<any> {
        return this.userService.getCheckIns(dates, memberid, limit, page);
    }

    getInvoices(dates, memberid, limit, page): Observable<any> {
        return this.userService.getInvoices(dates, memberid, limit, page);
    }

    /**
     * Updates credit card
     * @param {CreditCardUpdate} data
     * @returns {Observable<CreditCardUpdate>}
     */
    updateCreditCard(data: CreditCardUpdate) {
        return this.paymentService.updateCCInfo(data);
    }

    getAllFacilityAreasGlobal(): Observable<any> {
        return this.cmsService.getAllFacilityAreasGlobal();
    }

    refreshCreditCards(id: string | boolean): void {
        this.paymentService.getCCCardsInfo(id);
    }

    payOutStandingBalance(data: PayBalanceData, id: string): Observable<any> {
        return this.paymentService.payOutStandingBalance(data, id);
    }

    createNewReferral(referralData: NewReferral): Observable<any> {
        return this.referralService.createNewReferral(referralData);
    }

    referralList$(): Observable<any> {
        return this.referralService.referralList$;
    }

    referralServiceInit(): void {
        if (!this.referralServiceInitialized) {
            this.referralServiceInitialized = this.referralService.init();
        }

        return this.referralServiceInitialized;
    }
}
