/**
 * General id
 * */
export class General implements IGeneral {
    id: number;
    title: string;
    description: string;
    constructor() {
    }
}

export interface IGeneral {
    id: number;
    title: string;
    description: string;
}

