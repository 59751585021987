import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoggerService } from '../../core/services/log4ts/logger.service';

@Injectable()
export class SpinnerService {
  constructor(private logger: LoggerService) {
    this.logger.info('-- SpinnerService init --');
  }
  /**
   * @description spinners BehaviorSubject
   */
  public spinnerSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * To show spinner
   */
  show() {
    this.spinnerSubject.next(true);
  }

  /**
   * To hide spinner
   */
  hide() {
    this.spinnerSubject.next(false);
  }

  getMessage(): Observable<boolean> {
    return this.spinnerSubject.asObservable();
  }
}

