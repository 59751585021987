import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ib-prehead-notification',
    templateUrl: './prehead-notification.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class PreheadNotificationComponent implements OnInit {

    constructor() { }

    ngOnInit() {

    }

}


