export class WidgetSettings implements IWidgetSettings {
    id: number;
    date: Date;
    slug: string;
    type: string;
    link: string;
    title: SettingsTitle;
    acf: CustomSettingsContent;

    constructor() {
    }
}

export interface IWidgetSettings {
    id: number;
    date: Date;
    slug: string;
    type: string;
    title: SettingsTitle;
    acf: CustomSettingsContent;
}

export interface SettingsTitle {
    rendered: string;
}

export interface CustomSettingsContent {
    zendesk_trigger_information?: FacilityWidgetSettings[];
}

export interface FacilityWidgetSettings {
    moso_id: string;
    facility_name: string;
    show_widget: boolean;
    show_chat: boolean;
}
