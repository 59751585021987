import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import { constants } from '../../const/constants';

@Component({
    selector: 'ib-header',
    templateUrl: './header.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class HeaderComponent implements OnInit {
    public options: any;

    constructor() { }

    ngOnInit() {
        // this.options = constants.topHeaderNavigationItems;
    }

}


