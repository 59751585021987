import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {constants} from '../../const/constants';

declare var $: any;

@Component({
    selector: 'ib-top-navigation',
    templateUrl: './top-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class TopNavigationComponent implements OnInit {
    public headerMenu: any;
    public headerMobileMenu: any;

    constructor(public router: Router) { }

    private static myReplace(str: string) {
        if (str.indexOf('\n') !== -1) {
            return str.replace(/\n/g, '<br />');
        }
    }

    ngOnInit() {
        const navigationList = [
            {
                title: 'iBlink Home',
                link: '/iblink/home',
                className: ''
            },
            {
                title: 'Member Info',
                link: '/iblink/info',
                className: ''
            },
            {
                title: 'Billing',
                link: '/iblink/billing',
                className: ''
            },
            {
                title: 'Refer a Friend',
                link: '/iblink/referrals',
                className: ''
            },
            {
                title: 'Personal Training',
                link: '/iblink/startup',
                className: ''
            }];

        this.headerMenu = navigationList.map(e => {
            e.title = e.title;
            return e;
        });
        this.headerMobileMenu = constants.headerMobileMenusItems;
    }



    isActive(headerMenu: any, i: any) {
        return this.router.url === headerMenu[i].link;
    }

}


