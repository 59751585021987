<div class="dropdown dropdown-width" *ngIf="user$ | async">
	<div class="top-navigation">
		<span class="text-red-light nimbussans_bold mt-1 name-text">Hey, {{(user$ | async).givenName}}</span>
		<span class="ib-icon-member text-red-light"></span>
		<button type="button" class="btn btn-link" (click)="logOut()">
			<i class="icon-logout text-red-light"></i>
		</button>
  </div>

  <div
    *ngIf="isPastDate || balance < 0"
	  class="up-to-date"
	  [ngClass]="{'cursor-pointer' : balance > 0}"
	  (click)="openPopup()"
	>
	  <div
	    *ngIf="isPastDate"
	    class="balance w-100 position-absolute bg-blue"
	  >
	    <h5 class="text-white nimbussans_bold">Account Status: <b class="text-uppercase">late</b></h5>
	    <h5 class="text-white nimbussans_bold">Balance Due: <b>{{balance}}</b></h5>
	  </div>
	  <div
	    class="balance w-100 position-absolute bg-blue"
	    *ngIf="balance < 0"
	  >
	    <h5 class="text-white nimbussans_bold"><b class="text-uppercase">Pending Credit</b></h5>
	    <h5 class="text-white nimbussans_bold">Credit Amount: <b>{{balancePos}}</b></h5>
	  </div>
	</div>
</div>
