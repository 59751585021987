import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ib-simple-with-menu-layout',
    templateUrl: './simple-with-menu-layout.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SimpleWithMenuLayoutComponent {

}



