import { createAction, props } from '@ngrx/store';
import {
  ITermsOfUseRequest,
  TermsOfUseActionTypes,
} from './terms-of-use.actions.types';
import { ITermsOfUseState } from '../../reducers/terms-of-use/terms-of-use.reducer.types';


export const loadTermsOfUse = createAction(
  TermsOfUseActionTypes.request,
  props<ITermsOfUseRequest>(),
);

export const loadTermsOfUseSuccess = createAction(
  TermsOfUseActionTypes.requestSuccess,
  props<{ data: ITermsOfUseState }>(),
);

export const loadTermsOfUseFailure = createAction(
  TermsOfUseActionTypes.requestFailure,
  props<{ error: any }>(),
);
