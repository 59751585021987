import {Injectable, OnInit} from '@angular/core';
import {LoggerService} from './log4ts/logger.service';
import * as SettingsData from '../const/constants';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {MonthsList, StatesList} from '../../models';


@Injectable()
export class SettingsService implements OnInit {

    /** States List */
    private _statesList: BehaviorSubject<StatesList[]> = new BehaviorSubject(null);
    public readonly statesList$: Observable<StatesList[]> = this._statesList.asObservable().pipe(distinctUntilChanged());

    /** Months List */
    private _monthsList: BehaviorSubject<MonthsList[]> = new BehaviorSubject(null);
    public readonly monthsList$: Observable<MonthsList[]> = this._monthsList.asObservable().pipe(distinctUntilChanged());

    constructor( ) {
        this.init();
    }

    ngOnInit() {

    }
    /**
     * Getting CMS Settings Data
     * */
    init(): void {
        this._statesList.next(SettingsData.statesList);
        this._monthsList.next(SettingsData.monthsList);
    }

}
