<div class="modal fade" id="welcomePopup" tabindex="-1" role="dialog" aria-labelledby="welcomePopupLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content bg-blue">
			<div class="modal-header">
				<div class="col-12 px-0">
					<h3 class="modal-title text-center text-white">Book your FREE Start-Up<br /> Session now!*</h3>
					<button type="button" class="font-weight-light close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>
			<div class="modal-body mt-2">
				<div class="col-12 body-content text-center">
					<p class="mb-4">Set a goal and we'll show you how to get there - use your introductory session with a Personal Trainer today.</p>
					<a routerLink="/iblink/startup" class="mb-4 text-white nimbussans_bold" data-dismiss="modal">Click HERE to schedule.</a>
					<span>*1 Start-Up Session per member</span>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-white text-capitalize nimbussans_bold" data-dismiss="modal" (click)="onClick()">Take me to iblink</button>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="welcomePopup2" tabindex="-1" role="dialog" aria-labelledby="welcomePopup2Label" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content bg-blue" *ngIf="!startPopup">
			<div class="modal-header">
				<div class="col-12 px-0">
					<h3 class="modal-title text-center text-white">Almost done, {{user?.givenName}}!</h3>
					<button type="button" class="font-weight-light close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>
			<div class="modal-body my-4">
				<div class="col-12 body-content text-center">
					<p class="mb-4">We've sent you a membership purchase receipt and a list of Blink member benefits. All you have to do now is click "Next" to complete your registration.</p>
				</div>
			</div>
			<div class="modal-footer pb-0">
				<div class="col-6 text-center">
					<button type="button" class="btn btn-blue text-white text-capitalize nimbussans_bold border border-white mb-0" data-dismiss="modal" (click)="onClick()">Skip</button>
				</div>
				<div class="col-6 text-center">
					<button type="button" class="btn btn-white text-capitalize nimbussans_bold mb-0" data-dismiss="modal" 
					(click)="startPopup = !startPopup">Next</button>
				</div>
			</div>
			<div class="modal-points text-center">
				<p>&bull;<span class="text-warm-grey">&bull;</span></p>
			</div>
		</div>
	</div>
	<div class="modal-dialog" role="document" *ngIf="startPopup">
		<div class="modal-content bg-blue">
			<div class="modal-header">
				<div class="col-12 px-0">
					<h3 class="modal-title text-white">Book your FREE Start-Up<br> Session now!*</h3>
					<button type="button" class="font-weight-light close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
			</div>
			<div class="modal-body mt-2">
				<div class="col-12 body-content text-center">
					<p class="mb-4">Set a goal and we'll show you how to get there - use your introductory session with a Personal Trainer today.</p>
					<a routerLink="/iblink/startup" class="mb-4 text-white nimbussans_bold" data-dismiss="modal">Click HERE to schedule.</a>
					<span>*1 Start-Up Session per member</span>
				</div>
			</div>
			<div class="modal-footer pb-0">
				<div class="col-6 text-center">
					<button type="button" class="btn btn-blue text-white text-capitalize nimbussans_bold border border-white mb-0" data-dismiss="modal" 
					(click)="startPopup = !startPopup">Back</button>
				</div>
				<div class="col-6 text-center">
					<button type="button" class="btn btn-white text-capitalize nimbussans_bold mb-0" data-dismiss="modal" (click)="onClick()">Done</button>
				</div>
			</div>
			<div class="modal-points text-center">
				<p class="text-warm-grey">&bull;<span class="text-white">&bull;</span></p>
			</div>
		</div>
	</div>
</div>
