import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {User} from '../../../../models';
import {FacadeService} from '../../../../core/services/core/facade.service';
import {cleanSubscriptions} from '../../../../core/helpers';

@Component({
    selector: 'ib-success-screen',
    templateUrl: './success-screen.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SuccessScreenComponent implements OnInit, OnDestroy {
    public user: User;
    public subscriptions: Array<Subscription> = [];

    constructor(private _facadeService: FacadeService) {
    }

    ngOnInit() {
        this._facadeService.refreshCognitoUserData();
        this.subscriptions.push(
            this._facadeService.getCognitoUserDataOb().subscribe((data: User) => this.user = data)
        );
    }

    ngOnDestroy(): void {
        cleanSubscriptions(this.subscriptions);
    }

}
