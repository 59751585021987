import { createReducer, on } from '@ngrx/store';
import * as Actions from '../../actions/terms-of-use/terms-of-use.actions';
import {
  ITermsOfUseState
} from './terms-of-use.reducer.types';
import { AppState } from '../../store.types';
import { request, requestError } from './../common';
import { ITermsOfUseAction } from '../../actions/terms-of-use/terms-of-use.actions.types';

export const initialState: ITermsOfUseState = {
  loading: false,
};

export const termsOfUseReducer = createReducer(
  initialState,
  on(Actions.loadTermsOfUse, request),
  on(Actions.loadTermsOfUseSuccess, (state: ITermsOfUseState, { data }: ITermsOfUseAction): ITermsOfUseState => {
    return {
      ...state,
      loading: false,
      error: null,
      data: {
        ...state.data,
        ...data,
      },
  }}),
  on(Actions.loadTermsOfUseFailure, requestError),
);

/**
 * Gets termsOfUse reducer
 * @param {AppState} state
 * @returns {ITermsOfUseState}
 *  The termsOfUse state
 */
export const termsOfUse = (state: AppState): ITermsOfUseState =>
  state.termsOfUse;

