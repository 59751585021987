import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

/**
 * Custom Created Modules
 * */
import {SimpleLayoutComponent} from './layout/simple-layout.component';
import {MainLayoutComponent} from './layout/main-layout.component';
import {CheckoutLayoutComponent} from './layout/checkout-layout.component';
import {SimpleWithMenuLayoutComponent} from './layout/simple-with-menu-layout.component';

import {TopNavigationComponent} from './navigation/top-navigation.component';
import {FooterNavigationComponent} from './navigation/footer-navigation.component';
import {PreheadNotificationComponent} from './navigation/prehead-notification.component';
import {HeaderComponent} from './navigation/header.component';
import {LeftSideBarComponent} from './navigation/left-side-bar/left-side-bar.component';
import {LeftHiddenSideBarComponent} from './navigation/left-hidden-side-bar.component';
import {TopHeaderNavigationComponent} from './navigation/top-header-navigation.component';
import {TopHeaderMobileNavigationComponent} from './navigation/top-header-mobile-navigation.component';
import {NavbarComponent} from './navigation/navbar.component';

import {WelcomePopupComponent} from '../../secure/dashboard/shared/welcome-popup/welcome-popup.component';
//import { ToastService } from '../services/helpers/toast.service';
import {CorporateMembershipModalComponent} from './corporate-membership-modal/corporate-membership-modal.component';
import {ErrorModalComponent} from './error-modal/error-modal.component';
import {ModalComponent} from './modal/modal.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderLogoOnlyComponent} from './navigation/header-logo-only.component';
import {DownloadMobileBannerComponent} from '../../secure/dashboard/download-mobile-banner/download-mobile-banner.component';

@NgModule({
    declarations: [
        SimpleLayoutComponent,
        MainLayoutComponent,
        CheckoutLayoutComponent,
        SimpleWithMenuLayoutComponent,
        TopNavigationComponent,
        FooterNavigationComponent,
        PreheadNotificationComponent,
        HeaderComponent,
        HeaderLogoOnlyComponent,
        LeftSideBarComponent,
        LeftHiddenSideBarComponent,
        TopHeaderNavigationComponent,
        TopHeaderMobileNavigationComponent,
        NavbarComponent,
        ModalComponent,
        ErrorModalComponent,
        CorporateMembershipModalComponent,
        WelcomePopupComponent,
        DownloadMobileBannerComponent,
    ],
    exports: [
        TopNavigationComponent,
        FooterNavigationComponent,
        PreheadNotificationComponent,
        HeaderComponent,
        HeaderLogoOnlyComponent,
        LeftSideBarComponent,
        LeftHiddenSideBarComponent,
        TopHeaderNavigationComponent,
        TopHeaderMobileNavigationComponent,
        ModalComponent,
        ErrorModalComponent,
        CorporateMembershipModalComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
    ]
    /*providers: [
        ToastService
    ]*/
})
export class LayoutModule {
}
