/**
 * User Data Model (User)
 * */
export class CognitoUserModel implements ICognitoUserModel {
    givenName: string;
    familyName: string;
    phone: string;
    email: string;
    barcode: string;
    mosoId: string;
    securityId: string;

    constructor() {
    }
}

/**
 * iUser Data Model (IUser)
 * */
export interface ICognitoUserModel {
    givenName: string;
    familyName: string;
    phone: string;
    email: string;
    barcode: string;
    mosoId: string;
    securityId: string;
}









