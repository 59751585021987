<div [ngStyle]="{backgroundColor:banner.banner_background_color}" class="mobile-header-container">
    <div class="mobile-text-container"><p>{{banner.banner_text}}</p></div>

    <div class="mobile-button-container">
        <button (click)="onPressBanner()" class="get-app-button"> {{banner.cta_button_text}}</button>
    </div>

    <div class="mobile-close-container">
        <span (click)="onPressClose()" class="fa fa-close"></span>
    </div>
</div>

