import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CoreModule } from './core/core.module';
import { SpinnerModule } from './shared/spinner/spinner.module';
import { environment } from '../environments/environment';
import { reducers, metaReducers } from './store/reducers';
import { FacilitiesEffects } from './store/effects/facilities/facilities.effects';
import { FacilitiesByRegionEffects } from './store/effects/facilities-by-region/facilities-by-region.effects';
import { FacilitiesByGeoEffects } from './store/effects/facilities-by-geo/facilities-by-geo.effects';
import { FoldersEffects } from './store/effects/folders/folders.effects';
import { TermsAndConditionsEffects } from './store/effects/terms-and-conditions/terms-and-conditions.effects';
import { TermsOfUseEffects } from './store/effects/terms-of-use/terms-of-use.effects';
import { PrivacyPolicyEffects } from './store/effects/privacy-policy/privacy-policy.effects';
import { SuccessScreenComponent } from './secure/dashboard/shared/success-screen/success-screen.component';
import { UniversalPopupComponent } from './shared/universal-popup/universal-popup.component';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SpinnerModule.forRoot(),
    HammerModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    !environment.production
      ? StoreDevtoolsModule.instrument()
      : [],
    EffectsModule.forRoot([
      FacilitiesEffects,
      FacilitiesByRegionEffects,
      FacilitiesByGeoEffects,
      FoldersEffects,
      TermsAndConditionsEffects,
      TermsOfUseEffects,
      PrivacyPolicyEffects
    ]),
  ],
  declarations: [
    AppComponent,
    SuccessScreenComponent,
    UniversalPopupComponent
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: PathLocationStrategy,
  }],
  bootstrap: [AppComponent],
})
export class AppModule {}
