import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ib-header-logo-only',
    templateUrl: './header-logo-only.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class HeaderLogoOnlyComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}


