<div class="row d-none d-sm-block">
	<div class="col-12">
		<ib-top-header-mobile-navigation [menusOptions]="headerMobileMenu"></ib-top-header-mobile-navigation>
	</div>
</div>

<nav class="mt-3 mb-3 menu-hidden d-flex">
	<a *ngFor="let item of headerMenu; let i = index" class="navbar-brand w-25 nimbussans_black p-3 mr-0 text-center" [ngClass]="[item.className, 'tab-btn-' +i]" [attr.active]="isActive(headerMenu, i)" [routerLink]="[item.link]" >
		<span class="text">{{item.title}}</span>
	</a>
</nav>

<router-outlet></router-outlet>

