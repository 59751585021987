import { MembershipPlansInformation } from "../../../../models";

export enum AgreementsIndex {
  Green = 0,
  Blue = 1,
  Gray = 2,
  Orange = 3,
}

export interface IPlan extends MembershipPlansInformation {
  title: string;
}
