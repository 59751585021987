import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import {constants} from '../../const/constants';
import {environment} from '../../../../environments/environment';
import {Location} from '@angular/common';
import {ModalComponent} from '../modal/modal.component';
import {ActivatedRoute} from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
    selector: 'ib-footer-navigation',
    templateUrl: './footer-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class FooterNavigationComponent implements OnInit, AfterViewInit {
    public footerMenus: any;
    date: Date = new Date();
    mainDomain = environment.domain;
    corporateModalRef: ModalComponent;

    constructor(private location: Location, private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.footerMenus = constants.footerMenus;
    }

    openCorporateModal(e) {
        e.preventDefault();
        this.location.replaceState(e.srcElement.attributes.href.textContent);
        this.corporateModalRef.show();
    }

    ngAfterViewInit() {
        this.route.queryParams.pipe(
            filter((params: any) => params.modal)
        ).subscribe(params => {
                if (params.modal === 'corporate') {
                    this.corporateModalRef.show();
                }
            });
    }

}


