import {Inject, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DOCUMENT} from '@angular/common';
import {LoggerService} from './log4ts/logger.service';

declare var tagData: any;
declare var gtag: Function;
declare var _satellite: any;
declare var dataLayer: any;

export let browserRefresh = false;

@Injectable()
export class AnalyticsService {

    /**
     * AnalyticsService | constructor
     * @param document
     * @param {LoggerService} logger
     */
    constructor(@Inject(DOCUMENT) private document: any, private logger: LoggerService) {
        this.logger.info('-- AnalyticsService init --');
        tagData.iBlink = {};
        tagData.iBlink.experience = 'v2';
        tagData.global = {};
        tagData.account = {};
        tagData.global.pageArea = 'memb';
        tagData.global.pageSection = 'profile';
        tagData.ib_be_error = {};
    }

    // /**
    //  * AnalyticsService init(): void
    //  */
    // init(): void {
    //     this.adobeInit();
    // }
    //
    // /**
    //  * adobeInit()
    //  */
    // adobeInit() {
    //     tagData.iBlink = {};
    //     tagData.iBlink.experience = 'v2';
    //     tagData.global = {};
    //     tagData.account = {};
    //     tagData.global.pageArea = 'memb';
    //     tagData.global.pageSection = 'profile';
    //     tagData.ib_be_error = {};
    // }

    /**
     * addAccount(id: string, status: 'in' | 'out')
     * @param {string} id
     * @param {"in" | "out"} status
     */
    addAccount(id: string, status: 'in' | 'out'): void {
        tagData.account.id = id;
        tagData.global.loginStatus = status;
    }

    /**
     * changePageItem(path: string)
     * @param {string} path
     */
    changePageItem(path: string): void {
        tagData.global.pageItem = path;
        gtag('config', 'UA-108107889-2', {
            'page_location': environment.domain,
            'page_path': path
        });
        gtag('js', new Date());
    }

    /**
     * Personal Trainer Purchase Omniture Tag
     * @param {string} sessionType
     * @param {number} price
     */
    ptPurchase(sessionType: string, price: number): void {
        tagData.ptPurchase = {
            sessionType,
            price
        };
    }

    /**
     * satelliteAction(action: string): void
     * @param {string} action
     */
    satelliteAction(action: string): void {
        if (_satellite && _satellite.track) {
            return _satellite.track(action);
        }
    }

    setProp(prop, value) {
        tagData[prop] = value;
    }

    /**
     * errorRecord(error: string): void
     * @param {string} error
     */
    errorRecord(error: string): void {
        // disable by provider. Should be removed or changed
        tagData.ib_be_error = error;
    }

    browserRefreshState(state): void {
        browserRefresh = state;
    }

}
