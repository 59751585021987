import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { CmsService } from '../../../core/services/cms.service';
import {
  PrivacyPolicyActionTypes,
  IPrivacyPolicyRequest,
} from '../../actions/privacy-policy/privacy-policy.actions.types';

@Injectable()
export class PrivacyPolicyEffects {
  getPrivacyPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PrivacyPolicyActionTypes.request),
      mergeMap((action: IPrivacyPolicyRequest) =>
        this.cmsService.getGlobalPageDataBySlug(action.slug).pipe(
          map((data = []) => {
            return {
              type: PrivacyPolicyActionTypes.requestSuccess,
              data: {
                pdf: data[0]?.acf?.pdf_link || '',
                content: data[0]?.content?.rendered || '',
                title: data[0]?.title?.rendered || ''
              }
          }},
          catchError((error) =>
            of({
              type: PrivacyPolicyActionTypes.requestFailure,
              error,
            }),
          ),
        ),
      ),
    ),
  ));

  constructor(private actions$: Actions, private cmsService: CmsService) {}
}
