/**
 * CreditCard
 * */
export class PayBalanceData implements IPayBalanceData {
    token: string;
    postalCode: string | number;
    expirationDate: string | number;
    cvvCode: string | number;
    amount: string | number;
    facilityId: string | number;
    termsOfUse: string;
    constructor() {
    }
}

export interface IPayBalanceData {
    token: string;
    postalCode: string | number;
    expirationDate: string | number;
    cvvCode: string | number;
    amount: string | number;
    facilityId: string | number;
    termsOfUse: string;

}
