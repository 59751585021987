<ib-loading-spinner [template]="template" [timeout]="20000"></ib-loading-spinner>
<router-outlet *ngIf="isOnline;else isOffline"></router-outlet>
<ng-template #isOffline>
	<div>
		<h3 class="offline-error">{{'Without connection we can not load anything!'}} &nbsp;&nbsp;&nbsp;<span>:&nbsp;)</span></h3>
	</div>
</ng-template>


<div class="modal fade" [ngClass]="options.modalClass" id="toast" tabindex="-1" role="dialog" aria-labelledby="toastLabel" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h3 class="modal-title" id="toastLabel" *ngIf="options.modalTitle">{{options.modalTitle}}</h3>
				<button type="button" class="close close-change-card" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" [innerHTML]="options.modalMessage" ></div>
			<div class="modal-footer">
				<button type="button" *ngIf="!options.isHideButton" class="btn btn-primary" [ngClass]="options.modalButtonClass" [style.background-color]="options.modalButtonColor" data-dismiss="modal" (click)="onClick()">{{options.modalButtonTitle}}</button>
			</div>
		</div>
	</div>
</div>

<ib-universal-popup></ib-universal-popup>