import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AwsService} from './core/aws.service';
import {LoggerService} from './log4ts/logger.service';
import {AnalyticsService} from './analytics.service';
import {ToastService} from './helpers/toast.service';
import {environment} from '../../../environments/environment';
import {messagesOptions} from '../const/message-options';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {catchError, distinctUntilChanged, retry} from 'rxjs/operators';
import {LocalStorageService} from './helpers/local-storage.service';
import {CognitoUserModel, MosoUser, User} from '../../models';
import {CookieService} from 'ngx-cookie-service';
import {ScriptService} from './helpers/script.service';
import {AmplifyService} from 'aws-amplify-angular';
import {AwsAmplifyService} from './core/aws-amplify.service';
import * as moment from 'moment';

declare let $: any;

@Injectable()
export class UserService {
    cookieValue = null;
    private localCognitoUser: CognitoUserModel;
    private localMosoUser: MosoUser;

    public _user: BehaviorSubject<CognitoUserModel | null> = new BehaviorSubject(null);
    public readonly user$: Observable<CognitoUserModel | null> = this._user.asObservable().pipe(distinctUntilChanged());

    public _userDataMoso: BehaviorSubject<MosoUser | null> = new BehaviorSubject(null);
    public readonly userDataMoso$: Observable<MosoUser | null> = this._userDataMoso.asObservable().pipe(distinctUntilChanged());

    private _leftSidebarVisible: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public readonly leftSidebarVisible: Observable<boolean> = this._leftSidebarVisible.asObservable();

    private _showBridgeModal: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly showBridgeModal: Observable<boolean> = this._showBridgeModal.asObservable();


    constructor(
        private _awsService: AwsService,
        public amplifyService: AmplifyService,
        private logger: LoggerService,
        private _awsAmplifyService: AwsAmplifyService,
        private script: ScriptService,
        private toastService: ToastService,
        private cookieService: CookieService,
        private analyticsService: AnalyticsService
    ) {
        this.logger.info('-- UserService init --');
        this._awsService.checkIdentityCredentials();
        this.checkRedirectLocationCookies();
    }

    /////////////////////////////////////Refactoring Facade Service//////////////////////////////////////////////

    /**
     * Checking Cookies to get redirect location "Registration Bridge" and trigger PopUp
     * */
    checkRedirectLocationCookies(): void {
        this.script.load('popUpScript').then(data => {
            this.cookieValue = this.cookieService.get('locationBridge');

            if (this.cookieValue) {
                this.setShowBridgeModal(true);
                this.cookieService.delete('locationBridge', '/', '.blinkfitness.com');
                this.cookieValue = null;
            }
        }).catch(error => this.logger.error(error));
    }

    updateMosoUserInfo(userData: MosoUser): Observable<any> {
        return from(this.amplifyService.api().put('iBlinkMosoAPI', `/member/${userData.RoleId}`, {body: userData}));
    }


    setMosoUserData(userData: MosoUser): void {
        this._userDataMoso.next(userData);
    }

    /**
     * UserService. Get MOSO User by memberId
     * @returns {Observable<MosoUser>}
     * */
    getMosoUserByMosoId(memberId: string) {
        return from(this.amplifyService.api().get('iBlinkMosoAPI', `forgotEmail/${memberId}`, {}));
    }

    isMemberFirstTimeLogIn(): void {
        if (this.localMosoUser.MembershipAgreements) {
            this._awsService.getDataSetData('WelcomePopUp').then(() => {

            }).catch(() => {
                this.analyticsService.setProp('joinRegistration', {type: 'joinFlow'});
                this.analyticsService.satelliteAction('regConfirmed');
                this._awsService.setDataSetData('WelcomePopUp', '1')
                    .then(result => {
                    })
                    .catch(err => this.logger.error(err));
            });
        }
    }

    /**
     * UserService. Check user auth state
     * */
    isUserLoggedIn(): Promise<boolean> {
        return this._awsService.isLoggedIn();
    }

    /**
     * UserService. Get Cognito User Data /refreshCognitoUserData/, Get MOSO User Data by memberId
     * */
    getCognitoUserData(): void {
        if (this.isUserLoggedIn()) {
            this._awsAmplifyService.getCognitoUser()
                .then((cognitoUser) => {
                    this._awsAmplifyService.getCurrentUserAttrP(cognitoUser).then(attr => {
                            const cUser: CognitoUserModel = {
                                givenName: attr['given_name'],
                                familyName: attr['family_name'],
                                phone: attr['custom:phone_number'],
                                email: attr['email'],
                                barcode: attr['custom:barcode'],
                                mosoId: attr['custom:moso_member_id'],
                                securityId: attr['custom:userSecurityId']
                            };
                            this.analyticsService.addAccount(cUser.mosoId, 'in');
                            this._user.next(cUser);
                            this.localCognitoUser = cUser;
                            this.getUserDataMOSO(cUser.mosoId);
                            LocalStorageService.setLocaStorage('userId', cUser.mosoId);
                        }
                    );
                })
                .catch(error => this.logger.error('No Cognito User Data', error));
        }
    }

    /**
     * UserService. Get MOSO User by memberId
     * */
    getUserDataMOSO(memberId: string): void {
        if (memberId !== null) {
            from(this.amplifyService.api().get('iBlinkMosoAPI', `/member/${memberId}`, {})).subscribe((mosoUserData: MosoUser) => {
                    if (mosoUserData) {
                        this.localMosoUser = mosoUserData;
                        this._userDataMoso.next(mosoUserData);
                        this.isMemberFirstTimeLogIn();
                    }
                },
                error => {
                    this.logger.error('Can not get MOSO member data: ', error);
                    this.toastService.setToastVisible(messagesOptions.requestError);
                }
            );
        }
    }

    /**
     * request Pt Session
     * requestPtSession(userData: any): Observable<any>
     * */
    requestPtSession(data: any): Observable<any> {
        return from(this.amplifyService.api().post('iBlinkMosoAPI', `/member/${data.mosoId}/pt_request`, {body: data}));
    }

    /**
     * isFirstDate(): boolean
     * checking first data of the month
     * @returns {boolean}
     */
    isFirstDate(): boolean {
        const d = new Date();
        const date = d.getDate();
        return Number(date) === 1;
    }

    /**
     * setLeftSidebarVisible(value: boolean): void
     * Setting left side bar visible
     * @param {boolean} value
     */
    setLeftSidebarVisible(value: boolean): void {
        this._leftSidebarVisible.next(value);
    }

    setShowBridgeModal(value: boolean): void {
        this._showBridgeModal.next(value);
    }

    processCorporateEmail(contactInfo): Promise<any> {
        return this._sendCorporateEmail(contactInfo);
    }

    getCheckIns(dates, memberid, limit = 10, page = 1) {
        //const headers = new HttpHeaders().set('x-api-key', environment.API_CONF.X_API_KEY);
        //return this.httpClient.get<any>(`${environment.API_CONF.MOSO_API}/members/${memberid}/checkins?startDate=${moment(dates.start).format('YYYY-MM-DD')}&endDate=${moment(dates.end).format('YYYY-MM-DD')}`, {headers});
        return from(this.amplifyService.api().get('iBlinkMosoAPI', `/members/${memberid}/checkings?startDate=${moment(dates.start).format('MMDDYYYY') + '0000'}&endDate=${moment(dates.end).format('MMDDYYYY') + '2359'}&limit=${limit}&page=${page}`, {})).pipe(retry(1),
            catchError(err => {
                console.error('caught rethrown error, providing fallback value');
                return of({Results: []});
            })
        );
    }


    getInvoices(dates, memberid, limit = 10, page = 1) {
        //const headers = new HttpHeaders().set('x-api-key', environment.API_CONF.X_API_KEY);
        //return this.httpClient.get<any>(`${environment.API_CONF.MOSO_API}/members/${memberid}/checkins?startDate=${moment(dates.start).format('YYYY-MM-DD')}&endDate=${moment(dates.end).format('YYYY-MM-DD')}`, {headers});
        return from(this.amplifyService.api().get('iBlinkMosoAPI', `/members/${memberid}/invoices?startDate=${moment(dates.start).format('MMDDYYYY') + '0000'}&endDate=${moment(dates.end).format('MMDDYYYY') + '2359'}&limit=${limit}&page=${page}`, {})).pipe(retry(1),
            catchError(err => {
                console.error('caught rethrown error, providing fallback value');
                return of({Results: []});
            })
        );
    }

    _sendCorporateEmail(contactInfo) {
        return this._awsService.setUnauthIdentityCredentials()
            .then(() => {
                return this._awsService.idenityPermissions = this._awsService.getIdenityCredentials();
            })
            .then((credentials) => {
                if (credentials.identityId) {
                    const message = {
                        'email': contactInfo.email,
                        'first_name': contactInfo.first_name,
                        'lastname': contactInfo.last_name,
                        'phone': contactInfo.phone,
                        'company_name': contactInfo.company_name,
                        'numbers_employeers': contactInfo.number_of_employees,
                        'area_interest': contactInfo.area_of_interest
                    };

                    return this._awsService.sendContactUsEmail(message, environment.API_CONF.blinkCorporateEmail);
                }
            });
    }

}
