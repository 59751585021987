export class PTPurchaseData implements IPTPurchaseData {
    firstName: string;
    lastName: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber: string;
    emailAddress: string;
    emailAddressConfirm: string;
    birthDate: string;
    gender: string;
    alternateBillingStreet1: string;
    alternateBillingStreet2: string;
    alternatebillingCity: string;
    alternateBillingState: string;
    alternateBillingZip: string;
    creditCardName: string;
    creditCardNumber: string;
    creditCardExpirationDate: string;
    cVV: string;
    iAgree: string;
    planID: string;
    agreementType: string;
    RoleId: string;
    facilityId: string | number;
    facilityName: string;
    facilityEmail: string;
    termsOfUse: string;
}

export interface IPTPurchaseData {
    firstName: string;
    lastName: string;
    street1: string;
    street2: string;
    city: string;
    state: string;
    zip: string;
    phoneNumber: string;
    emailAddress: string;
    emailAddressConfirm: string;
    birthDate: string;
    gender: string;
    alternateBillingStreet1: string;
    alternateBillingStreet2: string;
    alternatebillingCity: string;
    alternateBillingState: string;
    alternateBillingZip: string;
    creditCardName: string;
    creditCardNumber: string;
    creditCardExpirationDate: string;
    cVV: string;
    iAgree: string;
    planID: string;
    agreementType: string;
    RoleId: string;
    facilityId: string | number;
    facilityName: string;
    facilityEmail: string;
    termsOfUse: string;
}
