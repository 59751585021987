import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { PtService } from '../../services/pt.service';


@Component({
    selector: 'ib-top-header-mobile-navigation',
    templateUrl: './top-header-mobile-navigation.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class TopHeaderMobileNavigationComponent implements OnInit {
    @Input() menusOptions: any;

    constructor(
        public ptService: PtService
    ) {
    }

    ngOnInit() {
    }

    onMyPersonalTraining() {
        this.ptService.myPersonalTraining();
    }
}
