<div class="container-fluid container-fix min-height-page">
    <ib-header></ib-header>

    <div class="row">
        <div class="col-12 bg-orange error-wrapper">
            <h1 class="text-center text-white text-uppercase mt-3 mb-0 pt-5 nimbussans_black">Surprise!</h1>
            <h3 class="text-center text-white nimbussans_bold">You’ve found our super-secret, feel-good page.</h3>
            <p class="text-center">If this isn’t where you wanted to go, your best bet is to go back to the home<br> page and start again.</p>
            <p class="text-center mt-4">If you'd like some assistance getting to the right place, please contact us at<br><a href="mailto:support@blinkfitness.com">support@blinkfitness.com</a></p>
            <div class="d-flex justify-content-center mt-4 position-relative">
                <a href="/iblink/home" class="btn btn-green text-center text-uppercase nimbussans_bold">Take me to the home page</a>
            </div>
            <p class="text-center mt-4">While you're here though: an interesting fact about this page is that the<br> background color we’ve used here is called “Papaya” and it tends to make<br> people feel energized.</p>
            <p class="text-center mt-4 mb-5 pb-5">You'll see it on the walls of all our gyms. Feel free to hang out here and soak<br> up the energy for as long as you want.</p>
        </div>
    </div>

	<ib-footer-navigation></ib-footer-navigation>
</div>

<ib-left-hidden-side-bar></ib-left-hidden-side-bar>

<!-- <ib-loading-spinner [template]="template"></ib-loading-spinner> -->

