/**
 * NewReferral
 * */
export class NewReferral implements INewReferral {
    referralFirstName: string;
    referralLastName: string;
    referralEmail: string;

    constructor() {
    }
}

export interface INewReferral {
    referralFirstName: string;
    referralLastName: string;
    referralEmail: string;
}


