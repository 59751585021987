import { createAction, props } from '@ngrx/store';
import { TermsAndConditionsPopupActionTypes } from './terms-and-conditions-popup.types';

export const loadTermsAndConditionsPopup = createAction(
    TermsAndConditionsPopupActionTypes.loadTermsAndConditionsPopup,
    props<{visible: boolean, title: string, content: string}>(),
);

export const destroyTermsAndConditionsPopup = createAction(
    TermsAndConditionsPopupActionTypes.destroyTermsAndConditionsPopup
);
