import { Injectable } from '@angular/core';
import { LoggerService } from '../log4ts/logger.service';

@Injectable()
export class MessageService {
    constructor(private logger: LoggerService) {
        this.logger.info('-- MessageService init --');
    }
    messages: string[] = [];

    add(message: string) {
        this.messages.push(message);
    }

    clear() {
        this.messages = [];
    }
}
