import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { get } from 'lodash';
import * as moment from 'moment';
import { CognitoUserModel, CreditCard, MosoUser, MembershipPlansInformation, Agreement, MembershipAgreements } from '../../../../models';
import { PaymentService } from '../../../services/payment.service';
import { PtService } from '../../../services/pt.service';
import { MemberStatesService } from '../../../services/helpers/member-states.service';
import { FacadeService } from '../../../services/core/facade.service';
import { AgreementsIndex, IPlan } from './left-side-bar-types';
import { cleanSubscriptions } from '../../../../core/helpers';

declare let $: any;
export const d = new Date();

@Component({
  selector: 'ib-left-side-bar',
  templateUrl: './left-side-bar.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LeftSideBarComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  sidebarPlansSubs: Subscription;
  creditCardsSubs: Subscription;
  plan: IPlan;
  user: CognitoUserModel;
  showTopAccordionLabel: boolean;
  isCardExpired: boolean;
  isPastDue: boolean;
  balance: string | number = 0;
  currentMonth: string | number = moment().add(1, 'month').format('MM');
  currentYear: string | number = moment().add(1, 'year').format('YY');
  mosoUser: MosoUser;
  nextBillingDate: string;
  memberHasNoActiveMembership: boolean;

  constructor(
    private _facadeService: FacadeService,
    public _paymentService: PaymentService,
    public ptService: PtService,
    private _memberStateService: MemberStatesService,
  ) {}

  /**
   * ngOnInit(): void
   */
  ngOnInit(): void {
    this.getUserData();
    this.showTopAccordionLabel = true;

    this.subscriptions.push(
      this.ptService.agreementsListByMemberId$
        .subscribe(this.onAgreementsListChange.bind(this))
    );
  }

  onAgreementsListChange(agreements: Agreement[]) {
    if (!agreements?.length) {
      return;
    }

    const plan = this.getAgreement<Agreement>(agreements);
    const billDate = plan?.KeyItemFirstBillDate;

    this.nextBillingDate = billDate ?
      moment(billDate).format('MM/DD/YY') :
      `${this.currentMonth}/01/${this.currentYear}`;
  }

  /**
   * getAgreement(agreements): MembershipAgreements
   * Gets the available agreement
   * @param {T[]} agreements
   * @returns {T}
   */
  getAgreement<T extends { AgreementName?: string; Name?: string }>(agreements: T[]): T {
    return agreements.find(value => {
      const name: string = value.AgreementName || value.Name;
      const index = AgreementsIndex[name];

      return !!index || index === 0;
    });
  }

  /**
   * setPlan(data, plan_details): void
   * Sets the available agreement information into the plan section
   * @param {MosoUser} data
   * @param {MembershipPlansInformation[]} plan_details
   */
  setPlan(data: MosoUser, plan_details: MembershipPlansInformation[]): void {
    if (!get(data, 'MembershipAgreements.length')) {
      this.plan = null;
      return;
    }

    const agreement = this.getAgreement<MembershipAgreements>(data.MembershipAgreements);

    if (!agreement) {
      this.plan = null;
      return;
    }

    const name = agreement.AgreementName;
    const details = plan_details[AgreementsIndex[name]];
    /** Adding this condition because of the introduction of Blue-Non-Commit Plan */
    /** Calculating agreement duration with the help of ObligationDate and StartDate- if it is around 1 month, then it is a no commitment plan */
    const agreementDuration = moment(agreement.ObligationDate).diff(moment(agreement.EditableStartDate), 'months', true);
    if (agreementDuration <= 1) {
      details.content_1 = 'No commitment.';
    }

    this.plan = {
      ...details,
      title: `Membership Plan: ${name}`,
    };
  }

  /**
   * getUserData(): void
   * Getting User data, getting MOSO user data
   */
  getUserData(): void {
    this.subscriptions.push(
      this._facadeService.getCognitoUserDataOb().subscribe((data: CognitoUserModel) => this.user = data),
      this._facadeService.getMosoUserDataOb().subscribe((mosoUser: MosoUser) => {
        if (mosoUser?.RoleId) {
          this.mosoUser = mosoUser;
          this._memberStateService.inactiveMembershipStatus().then(status => {
            this.memberHasNoActiveMembership = status;
          });
          this.sidebarPlansSubs = this._facadeService.getDataSetBySlug('sidebar-plans').subscribe((data) => {
            data.acf.membership_plans_information.forEach(detail => {
              detail.list_1 = detail.list_1.map(e => e.name);
              detail.list_2 = detail.list_2.map(e => e.name);
            });
            const plan_details = data.acf.membership_plans_information;
            this.setPlan(mosoUser, plan_details);
          });
          if (mosoUser?.Balance > 0) {
            this.balance = mosoUser.Balance.toFixed(2);
          }
          this.isPastDue = (this.mosoUser.AccountStatus === 'Past Due') && (this.balance > 0);
          this.creditCardsSubs = this._paymentService.creditCards.subscribe(
            (card: CreditCard[]) => {
              if (card) {
                const expirationMonth = Number(card[0].ExpirationMonth);
                const expirationYear = Number(card[0].ExpirationYear);
                if (expirationMonth && expirationYear) {
                  this.isCardExpired = this.checkCardExpired(expirationMonth, expirationYear);
                }
              }
            });
        }
      }));
  }

  /**
   * checking if credit card exp. And set current month and current Year
   * checkCardExpired(cardMonth, cardYear): boolean
   * @param cardMonth
   * @param cardYear
   * @returns {boolean}
   */
  checkCardExpired(cardMonth, cardYear): boolean {
    return this.currentYear > cardYear || (this.currentYear === cardYear && this.currentMonth > cardMonth);
  }

  /**
   * Showing popover
   * popover(): void
   */
  popover(): void {
    $('[data-toggle="popover"]').popover('show');
  }

  /*** ngOnDestroy **/
  ngOnDestroy(): void {
    cleanSubscriptions([
      this.sidebarPlansSubs,
      this.creditCardsSubs,
      ...this.subscriptions,
    ]);
  }

  onMyPersonalTraining() {
    this.ptService.myPersonalTraining();
  }

  /**
   * isMosoMemberHasJuniorGrayPlan(): boolean
   * @returns {boolean}
   */
  isMosoMemberHasCancellationDate(): boolean | void {
    if (this.mosoUser?.RoleId) {
      return this.mosoUser.MembershipAgreements
        ? !!this.mosoUser.MembershipAgreements[0].CancellationDate
        : null;
    }
  }
}


