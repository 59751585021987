import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { LoggerService } from '../log4ts/logger.service';


@Injectable()
export class HttpService {

    constructor(private httpClient: HttpClient, private logger: LoggerService) {
        this.logger.info('-- HttpService init --');
    }

    /**
     * Get Any data by passing Object Data name
     * */
    get(objectName: string): Observable<any> {
        return this.httpClient.get(objectName);
    }

    /**
     * Get Any data by passing Object/ ID
     * */
    getById(objectName: string, id: any): Observable<any> {
        return this.httpClient.get<any>(objectName + id);
    }

    /**
     * Post Any data by passing (objUrl: string, params: any)
     * */
    add(objUrl: string, data: any): Observable<any> {
        return this.httpClient.post(objUrl, data);
    }

    /**
     * Post Any data by passing (objUrl: string, params: any)
     * */
    addPayments(objUrl: string, params: any): Observable<any> {
        return this.httpClient.post(objUrl, params);
    }

    /**
     * extractData returning => res || {};
     * */
    private extractData(res) {
        return res || {};
    }

    /**
     * handle Error Observable
     * */
    private handleErrorObservable(error: Response | any) {
        this.logger.warn(error.message || error);
        return observableThrowError(error.message || error);
    }

    /**
     * handle Error Promise
     * */
    private handleErrorPromise(error: Response | any) {
        this.logger.error(error.message || error);
        return Promise.reject(error.message || error);
    }


}
