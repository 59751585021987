/**
 * General id
 * */
export class State implements IState {
    name: string;
    abbreviation: string;
    constructor() {
    }
}

export interface IState {
    name: string;
    abbreviation: string;
}

