import { createAction, props } from '@ngrx/store';
import {
  ITermsAndConditionsRequest,
  TermsAndConditionsActionTypes,
} from './terms-and-conditions.actions.types';
import { ITermsAndConditionsStateData } from '../../reducers/terms-and-conditions/terms-and-conditions.reducer.types';


export const loadTermsAndConditions = createAction(
  TermsAndConditionsActionTypes.request,
  props<ITermsAndConditionsRequest>(),
);

export const loadTermsAndConditionsSuccess = createAction(
  TermsAndConditionsActionTypes.requestSuccess,
  props<{ data: ITermsAndConditionsStateData }>(),
);

export const loadTermsAndConditionsFailure = createAction(
  TermsAndConditionsActionTypes.requestFailure,
  props<{ error: any }>(),
);
