<div id="menu-left" class="position-absolute">
	<div class="container-fluid">
		<div class="row">
			<div class="menu-hidden bg-navy w-100">
				<ul class="pt-4">
					<li *ngFor="let menu of menusHidden" class="list-unstyled pb-2">
						<a class="text-uppercase text-white nimbussans_bold" href="{{menu.link}}">{{ menu.title }}</a>
					</li>
        </ul>
        <br>
			</div>
		</div>
		<div class="row">
			<footer class="bg-white">
				<div class="col-12 pl-0 pt-4">
					<ul class="mb-0">
						<li class="list-unstyled"><a href="https://blinkfitness.zendesk.com/hc/en-us" target="_blank" class="footer-menu text-dark-grey nimbussans_bold">FAQs</a></li>
						<li class="list-unstyled"><a href="http://careers.blinkfitness.com/" class="footer-menu text-dark-grey nimbussans_bold">Careers at Blink</a></li>
					</ul>
				</div>
				<div class="col-12 pl-0 menu-hidden-2">
					<ul>
						<li *ngFor="let item of footerMenus" class="list-unstyled">
							<a href="{{item.link}}" class="footer-menu text-dark-grey">{{ item.title }}</a>
						</li>
					</ul>
				</div>
				<div class="col-12 pb-3 btn-social">
					<a href="/">
						<img class="d-block pb-4" src="../../../../assets/img/logoNew.svg" width="100" alt="">
					</a>
					<a href="https://www.facebook.com/BlinkFitness" target="_blank">
            <i class="fab fa-facebook-f pr-3 text-dark-grey icon"></i>
					</a>
          <a href="https://www.twitter.com/blinkfitness" target="_blank">
            <i class="fab fa-twitter pr-3 text-dark-grey icon"></i>
					</a>
          <a href="https://www.instagram.com/blinkfitness/" target="_blank">
            <i class="fab fa-instagram pr-3 text-dark-grey icon"></i>
					</a>
				</div>
				<div class="col-12 pl-0">
					<p class="copyright text-dark-grey">
						© {{ date | date: 'yyyy' }} Blink Fitness. All rights reserved.
					</p>
				</div>
			</footer>
		</div>
	</div>
</div>

