<div class="container-fluid container-fix min-height-page">
  <ib-header></ib-header>

  <ib-download-mobile-banner
    (onClose)="onCloseBanner()"
    [banner]="cmsData?.mobile_app_banner"
    *ngIf="cmsData && isMobile && !isClosed && cmsData.mobile_app_banner && cmsData.mobile_app_banner.active">
  </ib-download-mobile-banner>

	<div class="row">
		<ib-left-side-bar *ngIf="leftSidebarVisible" class="ib-col-lg-2 col-md-3 bg-white "></ib-left-side-bar>
		<ib-top-navigation class="col-sm-12 col-md-9 ib-col-lg-10 mt-2"></ib-top-navigation>
	</div>

	<ib-footer-navigation></ib-footer-navigation>
</div>

<ib-left-hidden-side-bar></ib-left-hidden-side-bar>

<ib-welcome-popup></ib-welcome-popup>
