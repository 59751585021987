import { Component, OnInit } from '@angular/core';


@Component({
    selector: 'ib-simple-layout',
    templateUrl: './simple-layout.component.html'
})
export class SimpleLayoutComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}
