import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { P404Component } from './public/other/404.component';
import { MainLayoutComponent } from './core/layouts/layout/main-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SimpleLayoutComponent } from './core/layouts/layout/simple-layout.component';
import { CheckoutLayoutComponent } from './core/layouts/layout/checkout-layout.component';
import { SuccessScreenComponent } from './secure/dashboard/shared/success-screen/success-screen.component';
import { SimpleWithMenuLayoutComponent } from './core/layouts/layout/simple-with-menu-layout.component';
import { DoNotShowOnRefreshGuard } from './core/guards/do-not-show-on-refresh.guard';

const routes: Routes = [{
    path: '',
    redirectTo: 'iblink',
    pathMatch: 'full'
  },
  {
    path: 'public',
    component: SimpleLayoutComponent,
    children: [{
      path: '',
      loadChildren: () =>
        import('./public/public.module')
          .then(m => m.PublicModule),
    }]
  },
  {
    path: 'iblink',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [{
      path: '',
      loadChildren: () =>
        import('./secure/secure.module')
          .then(m => m.SecureModule),
    }]
  },
  {
    path: 'checkout',
    component: CheckoutLayoutComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [{
      path: '',
      loadChildren: () =>
        import('./secure/dashboard/checkout/checkout.module')
          .then(m => m.CheckoutModule),
    }]
  },
  {
    path: 'success',
    component: SimpleWithMenuLayoutComponent,
    canActivate: [AuthGuard, DoNotShowOnRefreshGuard],
    canActivateChild: [AuthGuard, DoNotShowOnRefreshGuard],
    children: [{
      path: '',
      component: SuccessScreenComponent
    }]
  },
  {
    path: '**',
    component: P404Component
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
