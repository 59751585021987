/**
 * General id
 * */
export class Month implements IMonth {
    name: string;
    short: string;
    number: string;
    days: string;
    constructor() {
    }
}

export interface IMonth {
    name: string;
    short: string;
    number: string;
    days: string;
}
