/**
 * MemberAgreement Data Model (MemberAgreement)
 * */
export class MemberAgreement implements IMemberAgreement {
    CorporateAgreementCharacteristics: any[];
    AddonAgreements: any;
    AgreementType: string;
    AgreementNumber: number;
    AgreementId: number;
    Name: string;
    RoleId: string;
    AgreementClassificationId: number;
    AgreementClassificationName: string;
    Status: string;
    StartDate: Date;
    CancellationDate: any;
    ObligationDate: Date;
    ExpirationDate: any;
    IsSigned: boolean;
    Location: {Code: string; Name: string};
    PurchaseTerms: {Type: string; Name: string};
    CancellationReasonId: any;
    CancellationReason: any;
    DigitalSignature: any;
    RoleType: string;
    InitialInvoiceId: number;
    KeyItemFirstBillDate: string;
    KeyItemSchedule: any;
    KeyItemObligatedPayments: number;
    SalesPerson: {RoleId: string; Name: string};
    SalesAdvisors: {RoleId: string; Name: string};
    Items: {Code: string; Name: string; IsBundle: boolean};
    Promotions: {Code: string; Name: string; Description: string; PromotionId: number};
    Paysources: Paysource[];
    DisplaySummary: DisplaySummary;
    SavedAgreementInfo: any;
    InvoiceDetail: any;
    CommissionStatusCodes: any[];

    constructor() {
    }
}

/**
 * IMemberAgreement Data Model (IMemberAgreement)
 * */
export interface IMemberAgreement {
    CorporateAgreementCharacteristics: any[];
    AddonAgreements: any;
    AgreementType: string;
    AgreementNumber: number;
    AgreementId: number;
    Name: string;
    RoleId: string;
    AgreementClassificationId: number;
    AgreementClassificationName: string;
    Status: string;
    StartDate: Date;
    CancellationDate: any;
    ObligationDate: Date;
    ExpirationDate: any;
    IsSigned: boolean;
    Location: {Code: string; Name: string};
    PurchaseTerms: {Type: string; Name: string};
    CancellationReasonId: any;
    CancellationReason: any;
    DigitalSignature: any;
    RoleType: string;
    InitialInvoiceId: number;
    KeyItemFirstBillDate: string;
    KeyItemSchedule: any;
    KeyItemObligatedPayments: number;
    SalesPerson: {RoleId: string; Name: string};
    SalesAdvisors: {RoleId: string; Name: string};
    Items: {Code: string; Name: string; IsBundle: boolean};
    Promotions: {Code: string; Name: string; Description: string; PromotionId: number};
    Paysources: Paysource[];
    DisplaySummary: DisplaySummary;
    SavedAgreementInfo: any;
    InvoiceDetail: any;
    CommissionStatusCodes: any[];
}

/**
 * Paysource Data Model (Paysource)
 * */
export interface Paysource {
    PaySourceId: number;
    AccountId: number;
    AccountName: string;
    PaymentValue: number;
    PaymentValueType: string;
    Description: string;
}

/**
 * DisplaySummary Data Model (DisplaySummary)
 * */
export interface DisplaySummary {
    AddonCount: any;
    GroupDescription: string;
    Description: string;
    FinancialSummary: FinancialSummary;
    KeyItemSummary: KeyItemSummary;
    AdditionalItems: KeyItemSummary[];

}

/**
 * FinancialSummary Data Model (FinancialSummary)
 * */
export interface FinancialSummary {
    Tax: string;
    Discount: string;
    DownPayment: string;
    Total: string;
    PromotionDiscount: string;
}

/**
 * KeyItemSummary Data Model (KeyItemSummary)
 * */
export interface KeyItemSummary {
    Name: string;
    Quantity: number;
    FinancialSummary: FinancialSummary;
    Items: Item[];
}

/**
 * Item Data Model (Item)
 * */
export interface Item {
    Name: string;
    ItemCode: any;
    Quantity: number;
    FinancialSummary: FinancialSummary;

}








