import {Component, OnInit} from '@angular/core';

declare var $: any;

@Component({
    selector: 'ib-navbar',
    templateUrl: 'navbar.component.html'
})

export class NavbarComponent implements OnInit {
    activeNav = false;

    constructor() {

    }

    ngOnInit() {

    }

    sidebarToggle() {
        $('.container-fix').toggleClass('active');
        this.activeNav = !this.activeNav;
        $('#menu-left').toggleClass('active');
    }
}
