/**
 * CreditCard
 * */
export class CreditCard implements ICreditCard {
    PaySourceId: number;
    ClientAccountId: number;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
    Email: string;
    Mask: string;
    Token: string;
    DisplayMask: string;
    ExpirationYear: number;
    ExpirationMonth: number;
    CreditCardType: string;
    TenderTypeId: number;
    TenderInterface: string;
    IsValid: boolean;
    IsActive: boolean;
    OnAccount: boolean;
    UseForRecurring: boolean;
    UseForNonRecurring: boolean;
    BankName: number;
    ABARouting: number;
    AccountName: string;
    DisplayName: string;
    Description: string;
    BankAccountType: string;
    CardHolderName: string;
    constructor() {
    }
}

export interface ICreditCard {
    PaySourceId: number;
    ClientAccountId: number;
    Address1: string;
    Address2: string;
    City: string;
    State: string;
    PostalCode: string;
    Country: string;
    Email: string;
    Mask: string;
    Token: string;
    DisplayMask: string;
    ExpirationYear: number;
    ExpirationMonth: number;
    CreditCardType: string;
    TenderTypeId: number;
    TenderInterface: string;
    IsValid: boolean;
    IsActive: boolean;
    OnAccount: boolean;
    UseForRecurring: boolean;
    UseForNonRecurring: boolean;
    BankName: number;
    ABARouting: number;
    AccountName: string;
    DisplayName: string;
    Description: string;
    BankAccountType: string;
    CardHolderName: string;

}
/*{
    "facilityId": "640",
    "CreditCardNumber": 341111597241002,
    "AccountAccessory": "1225",
    "CreditCardDetails": {
        "CreditCardType": "Americanexpress",
        "ExpirationMonth": 12,
        "ExpirationYear": 2025
    },
    "Description": "description",
    "OnAccountFundingSource": true,
    "Invalid": false,
    "IsActive": true,
    "UseForRecurring": false,
    "UseForNonRecurring": false,
    "AccountHolderName": "holderName",
    "Address": "address1",
    "Address2": "address2",
    "City": "city",
    "StateProvince": "TX",
    "PostalCode": 76021,
    "Country": "USA",
    "Phone": "1234567899",
    "EmailAddress": "asd@asd.com",
    "DigitalSignature": "my awesome signature"
}*/

