import { Injectable, OnDestroy } from '@angular/core';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {LoggerService} from '../log4ts/logger.service';

@Injectable()
export class ToastSecondService implements OnDestroy {
    public _showToast: BehaviorSubject<any> = new BehaviorSubject({visible: false, options: null});
    public showToast: Observable<boolean> = this._showToast.asObservable();

    constructor(private router: Router, public logger: LoggerService) {
    }

    setToastVisible(val: any) {
        this._showToast.next(val);
    }

    ngOnDestroy(): void {

    }

}


























