import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
/**
 * Custom Created Modules
 * */
import {LayoutModule} from './layouts/layout.module';
/**
 * Custom Created Components
 * */
import {P404Component} from '../public/other/404.component';
import {P500Component} from '../public/other/500.component';
import {ServicesModule} from './services/services.module';

/**
 * Custom Created services
 * */


@NgModule({
    declarations: [
        P404Component,
        P500Component
    ],
    imports: [
        BrowserModule,
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        LayoutModule,
        ServicesModule
    ],
    providers: [
    ],
    bootstrap: [],
    exports: [P404Component, P500Component]
})
export class CoreModule {
}
