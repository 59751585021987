import { ITermsOfUseState } from '../../reducers/terms-of-use/terms-of-use.reducer.types';
import { IBaseAction } from '../common.types';

export enum TermsOfUseActionTypes {
  request = '[Terms Of Use] Load',
  requestSuccess = '[Terms Of Use] Load Success',
  requestFailure = '[Terms Of Use] Load Failure',
}

export interface ITermsOfUseAction extends IBaseAction {
  data: ITermsOfUseState;
}

export interface ITermsOfUseRequest {
  slug: string;
}
