import { createReducer, on } from '@ngrx/store';
import * as Actions from '../../actions/terms-of-use-popup/terms-of-use-popup.actions';
import { AppState } from '../../store.types';
import { ITermsOfUsePopupState } from './terms-of-use-popup.reducer.types';

export const initialState: ITermsOfUsePopupState = {
  visible: false,
  title: null,
  content: null
};

export const onLoadTermsOfUsePopup = (state: ITermsOfUsePopupState, { visible, content, title }: ITermsOfUsePopupState): ITermsOfUsePopupState => {
  return { ...state, visible, title, content };
}

export const onDestroyTermsOfUsePopup = (): ITermsOfUsePopupState => {
  return { ...initialState };
}

export const termsOfUsePopupReducer = createReducer(
  initialState,
  on(Actions.loadTermsOfUsePopup, onLoadTermsOfUsePopup.bind(this)),
  on(Actions.destroyTermsOfUsePopup, onDestroyTermsOfUsePopup.bind(this)),
);

export const termsOfUsePopup = (state: AppState) => state.termsOfUsePopup;