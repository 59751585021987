import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {User} from '../../../../models';
import {FacadeService} from '../../../../core/services/core/facade.service';
import {cleanSubscriptions} from '../../../../core/helpers';


@Component({
    selector: 'ib-welcome-popup',
    templateUrl: './welcome-popup.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./welcome-popup.component.scss']
})

export class WelcomePopupComponent implements OnInit, OnDestroy {
    public user: User;
    public subscriptions: Array<Subscription> = [];
    public popupType: boolean;
    public startPopup: boolean;

    constructor(private _facadeService: FacadeService) {
    }

    ngOnInit() {
        this._facadeService.refreshCognitoUserData();
        this.subscriptions.push(this._facadeService.getCognitoUserDataOb().subscribe((data: User) => this.user = data));
        this.popupType = false;
    }


    onClick() {
    }

    ngOnDestroy(): void {
        cleanSubscriptions(this.subscriptions);
    }

}
