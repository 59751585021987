import { Injectable } from '@angular/core';
import { SpinnerService } from '../../shared/spinner/spinner.service';
import { LoggerService } from './log4ts/logger.service';
import { ToastService } from './helpers/toast.service';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { UserService } from './user.service';
import { CognitoUserModel, NewReferral, Referral } from '../../models';
import { messagesOptions } from '../const/message-options';
import { AmplifyService } from 'aws-amplify-angular';


@Injectable()
export class ReferralService {
    private user: CognitoUserModel;

    private _referralList: BehaviorSubject<Referral[] | null> = new BehaviorSubject(null);
    public readonly referralList$: Observable<Referral[] | null> = this._referralList.asObservable().pipe(distinctUntilChanged());

    /**
     * ReferralService constructor
     * @param {SpinnerService} spinnerService
     * @param {LoggerService} logger
     * @param {ToastService} toastService
     * @param {UserService} _userService
     * @param {AmplifyService} amplifyService
     */
    constructor(
        private spinnerService: SpinnerService,
        private logger: LoggerService,
        public toastService: ToastService,
        private _userService: UserService,
        public amplifyService: AmplifyService
    ) {
        this.logger.info('-- ReferralService init --');
    }

    /**
     * Init for ReferralService service
     **/
    init(): void {
        this._userService.user$.subscribe((user: CognitoUserModel) => {
            if (user && user.mosoId) {
                this.user = user;
                this.getReferrals(user.mosoId);
            }
        });
    }

    getReferrals(mosoId: string): void {
        this.spinnerService.show();
        from(this.amplifyService.api().get('iBlinkReferralsAPI', `/referrals/list/${mosoId}`, '')).pipe(
            map((referralsList: Referral[]) => {
                return referralsList.sort((a, b) => new Date(b.referralCreatedDate).getTime() - new Date(a.referralCreatedDate).getTime());
            })
        ).subscribe(
            (res: Referral[]) => {
                this._referralList.next(res);
                this.spinnerService.hide();
            },
            (error: Error) => {
                this.spinnerService.hide();
                this.logger.warn(error);
                this.toastService.setToastVisible(messagesOptions.requestError);
            }
        );
    }

    /**
     * Creates new referral
     * @param {NewReferral} referralData
     * @returns {Observable<NewReferral>}
     */
    createNewReferral(referralData: NewReferral) {
        if (this.user && this.user.mosoId) {
            const apiOptions = {};
            apiOptions['headers'] = {
                'Content-Type': 'application/json'
            };
            apiOptions['body'] = referralData;
            return from(this.amplifyService.api().post('iBlinkReferralsAPI', `/referrals/${this.user.mosoId}`, apiOptions).then(response => response).catch(error => error));
        }
    }

    getReferralList() {
        if (this.user && this.user.mosoId) {
            return from(this.amplifyService.api().get('iBlinkReferralsAPI', `/referrals/list/${this.user.mosoId}`, ''));
        }
    }
}
