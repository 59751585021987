import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalComponent} from '../modal/modal.component';
import {constants} from '../../const/constants';
import {UserService} from '../../services/user.service';
import {validateAllFormFields} from '../../helpers';
import {SpinnerService} from '../../../shared/spinner/spinner.service';
import {CmsService} from '../../services/cms.service';
import {FacadeService} from '../../services/core/facade.service';
import {Observable} from 'rxjs';

const PATTERNS = constants.PATTERNS;

@Component({
    selector: 'ib-corporate-membership-modal',
    templateUrl: './corporate-membership-modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class CorporateMembershipModalComponent implements OnInit {
    form: FormGroup;
    modalUIRef: ModalComponent;
    status = 'corporate';
    @Output('onReady') onReady = new EventEmitter<ModalComponent>();
    @Output('onModalProcessing') onModalProcessing = new EventEmitter<any>();
    @Output('onModalStopProcessing') onModalStopProcessing = new EventEmitter<any>();
    formSubmitted = false;
    // cmsData = {};
    cmsData$: Observable<any>;

    constructor(private userService: UserService, private _facadeService: FacadeService, private spinnerService: SpinnerService, private cmsService: CmsService) {
    }

    ngOnInit() {
        this.init();
        this.cmsData$ = this._facadeService.getGlobalDataSetBySlug('corporate-program-interest-form');


        // this.cmsService.getData('corporate-program-interest-form').toPromise().then((data: any[]) => {
        //     this.cmsData = data.filter(elem => elem.status === 'publish').shift();
        // }).catch((err) => {
        //     const mockData = [
        //         {
        //             'id': 184,
        //             'date': '2018-07-19T16:54:14',
        //             'date_gmt': '2018-07-19T16:54:14',
        //             'guid': {
        //                 'rendered': 'http://dev.cms.sls.blinkfitness.com/?page_id=184'
        //             },
        //             'modified': '2018-07-19T17:13:29',
        //             'modified_gmt': '2018-07-19T17:13:29',
        //             'slug': 'corporate-program-interest-form-dev',
        //             'status': 'publish',
        //             'type': 'page',
        //             'link': 'http://dev.cms.sls.blinkfitness.com/corporate-program-interest-form-dev/',
        //             'title': {
        //                 'rendered': 'Corporate Program Interest Form DEV'
        //             },
        //             'content': {
        //                 'rendered': '',
        //                 'protected': false
        //             },
        //             'excerpt': {
        //                 'rendered': '',
        //                 'protected': false
        //             },
        //             'author': 2,
        //             'featured_media': 0,
        //             'parent': 0,
        //             'menu_order': 0,
        //             'comment_status': 'closed',
        //             'ping_status': 'closed',
        //             'template': '',
        //             'meta': [],
        //             'acf': {
        //                 'corporate_modal': {
        //                     'header_copy': 'Want to get everyone at work discounted Blink memberships?',
        //                     'subhead_copy': 'Fill out the form below for more info on the Blink Corporate Program.',
        //                     'number_of_employees': [
        //                         {
        //                             'item': '10-49'
        //                         },
        //                         {
        //                             'item': '50-99'
        //                         },
        //                         {
        //                             'item': '100-499'
        //                         },
        //                         {
        //                             'item': '500-999'
        //                         },
        //                         {
        //                             'item': '1000+'
        //                         }
        //                     ],
        //                     'area_of_interest': [
        //                         {
        //                             'area_of_interest_item': 'Manhattan'
        //                         },
        //                         {
        //                             'area_of_interest_item': 'Brooklyn'
        //                         },
        //                         {
        //                             'area_of_interest_item': 'Bronx'
        //                         },
        //                         {
        //                             'area_of_interest_item': 'Queens'
        //                         },
        //                         {
        //                             'area_of_interest_item': 'New Jersey'
        //                         },
        //                         {
        //                             'area_of_interest_item': 'Long Island'
        //                         },
        //                         {
        //                             'area_of_interest_item': 'Westchester'
        //                         },
        //                         {
        //                             'area_of_interest_item': 'Pennsylvania'
        //                         },
        //                         {
        //                             'area_of_interest_item': 'Other'
        //                         }
        //                     ]
        //                 },
        //                 'corporate_modal_success': {
        //                     'success_header_copy': 'Thanks!',
        //                     'success_subheader_copy': 'We\'ll be in touch.'
        //                 }
        //             },
        //             '_links': {
        //                 'self': [
        //                     {
        //                         'href': 'http://dev.cms.sls.blinkfitness.com/wp-json/wp/v2/pages/184'
        //                     }
        //                 ],
        //                 'collection': [
        //                     {
        //                         'href': 'http://dev.cms.sls.blinkfitness.com/wp-json/wp/v2/pages'
        //                     }
        //                 ],
        //                 'about': [
        //                     {
        //                         'href': 'http://dev.cms.sls.blinkfitness.com/wp-json/wp/v2/types/page'
        //                     }
        //                 ],
        //                 'author': [
        //                     {
        //                         'embeddable': true,
        //                         'href': 'http://dev.cms.sls.blinkfitness.com/wp-json/wp/v2/users/2'
        //                     }
        //                 ],
        //                 'replies': [
        //                     {
        //                         'embeddable': true,
        //                         'href': 'http://dev.cms.sls.blinkfitness.com/wp-json/wp/v2/comments?post=184'
        //                     }
        //                 ],
        //                 'version-history': [
        //                     {
        //                         'href': 'http://dev.cms.sls.blinkfitness.com/wp-json/wp/v2/pages/184/revisions'
        //                     }
        //                 ],
        //                 'wp:attachment': [
        //                     {
        //                         'href': 'http://dev.cms.sls.blinkfitness.com/wp-json/wp/v2/media?parent=184'
        //                     }
        //                 ],
        //                 'curies': [
        //                     {
        //                         'name': 'wp',
        //                         'href': 'https://api.w.org/{rel}',
        //                         'templated': true
        //                     }
        //                 ]
        //             }
        //         }
        //     ];
        //     this.cmsData = mockData.filter(elem => elem.status === 'publish').shift();
        // });
    }


    init() {
        this.status = 'corporate';
        this.form = new FormGroup({
            company_name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(PATTERNS.CORPORATE_MEMBERSHIP_TEXT)]),
            number_of_employees: new FormControl('', []),
            first_name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(PATTERNS.CORPORATE_MEMBERSHIP_TEXT)]),
            last_name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern(PATTERNS.CORPORATE_MEMBERSHIP_TEXT)]),
            email: new FormControl('', [Validators.required, Validators.pattern(PATTERNS.EMAIL)]),
            phone: new FormControl('', [Validators.required, Validators.pattern(PATTERNS.PHONE)]),
            area_of_interest: new FormControl('', [Validators.required]),
        });
    }

    submit(e) {
        e.preventDefault();
        this.formSubmitted = true;
        validateAllFormFields(this.form);
        if (!this.form.valid) {

        } else {
            this.processingSyncStart();
            this.userService.processCorporateEmail(this.form.getRawValue()).then(() => {
                // this.modalUIRef.close();
                this.status = 'success';
                this.form.reset();
                this.processingSyncStop();
            }).catch(() => {
                this.processingSyncStop();
            });
        }
    }

    processingSyncStart(): any {
        this.spinnerService.show();
        this.onModalProcessing.emit();
    }

    processingSyncStop(): any {
        this.spinnerService.hide();
        this.onModalStopProcessing.emit();
    }

}
