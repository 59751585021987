// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const API_URL = 'https://qa.api.sls.blinkfitness.com';
export const environment = {
    production: false,
    isDebugMode: true,
    isLocalRun: false,
    awsConfig: {
        region: 'us-east-1',
        APIregion: 'us-east-1',
        IdentityPoolId: 'us-east-1:f6adb3cb-4514-4159-9ffa-f483db4bbe0e',
        UserPoolId: 'us-east-1_vXxfGI9eV',
        UserPoolIdRegion: 'us-east-1',
        ClientId: '22getrh34h8a0p6e292mja2hkh',
        cognito_idp_endpoint: '',
        cognito_identity_endpoint: '',
        storageDomain: '.blinkfitness.com'
    },
    API_CONF: {
        API_URL: API_URL,
        CMS_API: API_URL + '/iblink.qa',
        GLOBAL_CMS_API: API_URL + '/web.qa',
        MOSO_API: API_URL + '/moso-api',
        REFERRALS_API: API_URL + '/referrals-api-qa',
        COGNITO_API: API_URL + '/cognito-qa',
        PRICING_API: API_URL + '/pricing-api-qa',
        WEB_MOSO_API: API_URL + '/web-blink.moso-api-qa',
        LEADS_API: API_URL + '/blink-leads-qa',
        blinkContactUsEmail: 'arn:aws:sns:us-east-1:923195502435:web-blink-qa-contact-us-email',
        blinkCorporateEmail: 'arn:aws:sns:us-east-1:923195502435:iblink-qa-corporate-membership-email',
        X_API_KEY: 'no1live4everno1live4everqa',
        YEXT_KEY: 'e0f698e7fe1b34f06c77641a2ed3dcd7',
    },
    domain: 'https://qa.web.sls.blinkfitness.com',
    domainSelf: 'https://qa.account.blinkfitness.com',
    inTakePopUpSnippetURL: 'https://d311r7cfs9wexv.cloudfront.net/dev/b2-intake-popup.js',
    zenDeskWidgetURL: 'https://static.zdassets.com/ekr/snippet.js?key=23b5b825-eb2e-4287-a38e-c6feb84b5041'
};


export const AuthConf = {
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: environment.awsConfig.IdentityPoolId,

        // REQUIRED - Amazon Cognito Region
        region: environment.awsConfig.UserPoolIdRegion,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: environment.awsConfig.UserPoolIdRegion,

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: environment.awsConfig.UserPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        // userPoolWebClientId: '22getrh34h8a0p6e292mja2hkh',
        userPoolWebClientId: environment.awsConfig.ClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
    },
    API: {
        endpoints: [
            {
                name: 'globalCMSAPI',
                endpoint:  `${environment.API_CONF.GLOBAL_CMS_API}`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.API_CONF.X_API_KEY };
                }
            },
            {
                name: 'iBlinkCMSAPI',
                endpoint:  `${environment.API_CONF.CMS_API}`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.API_CONF.X_API_KEY };
                }
            },
            {
                name: 'iBlinkMosoAPI',
                endpoint: `${environment.API_CONF.MOSO_API}`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.API_CONF.X_API_KEY };
                }
            },
            {
                name: 'iBlinkReferralsAPI',
                endpoint:  `${environment.API_CONF.REFERRALS_API}`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.API_CONF.X_API_KEY};
                }
            },
            {
                name: 'iBlinkCognitoAPI',
                endpoint: `${environment.API_CONF.COGNITO_API}`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.API_CONF.X_API_KEY };
                }
            },
            {
                name: 'iBlinkPricingAPI',
                endpoint: `${environment.API_CONF.PRICING_API}`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.API_CONF.X_API_KEY };
                }
            },
            {
                name: 'iBlinkWebMosoAPI',
                endpoint: `${environment.API_CONF.WEB_MOSO_API}`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.API_CONF.X_API_KEY };
                }
            },
            {
                name: 'iBlinkLeadAPI',
                endpoint: `${environment.API_CONF.LEADS_API}`,
                region: 'us-east-1',
                custom_header: async () => {
                    return { 'x-api-key': environment.API_CONF.X_API_KEY };
                }
            }
        ]
    },
    aws_appsync_region: environment.awsConfig.APIregion,
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: environment.API_CONF.X_API_KEY,
};
