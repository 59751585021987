import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import * as Actions from '../../actions/facilities-by-geo/facilities-by-geo.actions';
import { IFacilitiesByGeoState } from './facilities-by-geo.reducer.types';
import { IFacilitiesByGeoAction } from '../../actions/facilities-by-geo/facilities-by-geo.actions.types';
import { AppState } from '../../store.types';

/**
 * Initial state for the reducer.
 * That's the value when page loads.
 */
export const initialState: IFacilitiesByGeoState[] = [];
/**
 * Facilities By State Reducer
 */
export const facilitiesByGeoReducer = createReducer(
  initialState,
  on(Actions.loadFacilitiesByGeoSearchSuccess, (
    state: IFacilitiesByGeoState[],
    { facilities, search }: IFacilitiesByGeoAction,
  ): IFacilitiesByGeoState[] => [
    ...state,
    { search, facilities },
  ]),
);

/**
 * Gets facilitiesByGeo reducer
 * @param {AppState} state
 * @returns {IFacilitiesByGeoState}
 *  The facilitiesByGeo state
 */
export const facilitiesByGeo = (state: AppState): IFacilitiesByGeoState[] =>
  state.facilitiesByGeo;
