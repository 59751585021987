

<ib-modal (onClose)="init()"  (onReady)="onReady.emit($event);modalUIRef=$event;" #modalRef>
    <div class='ib-modal-content' *ngIf="cmsData$ | async as cmsData">
        <div *ngIf="status=='corporate'" >
            <div class="d-flex justify-content-center mt-4">
                <div class="col-md-10 col-lg-10 col-xl-10 col-sm-12 col-xs-12 px-0 text-center">
                    <h3 class="modal-title text-center nimbussans_bold tc-modal-title"
                        style="line-height: 34px;">
                        {{cmsData.acf?.corporate_modal.header_copy}} </h3>
                    <br>
                    <p class="gray" style="font-size:18px">{{cmsData.acf?.corporate_modal.subhead_copy}} </p>

                    <div>
                        <div class="text-left">
                            <form [formGroup]="form" novalidate>
                                <br>
                                <span style="font-size:10px;">*required</span>
                                <div class="form-group  has-icon  inner-addon right-addon label-floating"
                                     [ngClass]="{'has-error': form.controls.company_name.invalid && (form.controls.company_name.touched || form.controls.company_name.dirty)}">
                                    <input name="company_name" id="company_name" formControlName="company_name" type="text"
                                           placeholder=" "
                                           class="form-control pt-0">
                                    <label for="company_name" class="control-label  pt-2 mb-1">company name*</label>
                                    <span *ngIf="form.controls.company_name.hasError('required')" class="error-text">please enter a company name
                            </span>
                                    <span *ngIf="form.controls.company_name.hasError('minlength')" class="error-text">Min 2 characters
                            </span>
                                    <span *ngIf="form.controls.company_name.hasError('maxlength')" class="error-text">Max 50 characters
                            </span>
                                    <span *ngIf="form.controls.company_name.hasError('pattern') && !form.controls.company_name.hasError('maxlength') && !form.controls.company_name.hasError('minlength')"
                                          class="error-text">please enter a valid company name
                            </span>
                                </div>


                                <div class="form-group label-floating select"
                                     [ngClass]="{'has-error': form.controls['number_of_employees'].invalid && (form.controls['number_of_employees'].touched || form.controls['number_of_employees'].dirty) }">
                                    <select formControlName="number_of_employees" id="number_of_employees"
                                            class="form-control py-0">
                                        <option value="" disabled selected hidden>select the number of employees</option>
                                        <option  *ngFor='let number of cmsData.acf?.corporate_modal.number_of_employees'  [value]="number.item">{{number.item}}</option>
                                    </select>
                                    <label for="number_of_employees" class="control-label  pt-2 mb-1">number of
                                        employees</label>
                                   
                                </div>


                                <div class="form-group  has-icon  inner-addon right-addon label-floating"
                                     [ngClass]="{'has-error': form.controls.first_name.invalid && (form.controls.first_name.touched || form.controls.first_name.dirty)}">
                                    <input name="first_name" id="first_name" formControlName="first_name" type="text"
                                           placeholder=" "
                                           class="form-control pt-0">
                                    <label for="first_name" class="control-label  pt-2 mb-1">first name*</label>
                                    <span *ngIf="form.controls.first_name.hasError('required')" class="error-text">please enter your first name
                            </span>
                                    <span *ngIf="form.controls.first_name.hasError('minlength')" class="error-text">Min 2 characters
                            </span>
                                    <span *ngIf="form.controls.first_name.hasError('maxlength')" class="error-text">Max 50 characters
                            </span>
                                    <span *ngIf="form.controls.first_name.hasError('pattern') && !form.controls.first_name.hasError('maxlength') && !form.controls.first_name.hasError('minlength')"
                                          class="error-text">please enter a valid first name
                            </span>
                                </div>


                                <div class="form-group  has-icon  inner-addon right-addon label-floating"
                                     [ngClass]="{'has-error': form.controls.last_name.invalid && (form.controls.last_name.touched || form.controls.last_name.dirty)}">
                                    <input name="last_name" id="last_name" formControlName="last_name" type="text"
                                           placeholder=" "
                                           class="form-control  pt-0">
                                    <label for="last_name" class="control-label  pt-2 mb-1">last name*</label>
                                    <span *ngIf="form.controls.last_name.hasError('required')" class="error-text">please enter your last name
                            </span>
                                    <span *ngIf="form.controls.last_name.hasError('minlength')" class="error-text">Min 2 characters
                            </span>
                                    <span *ngIf="form.controls.last_name.hasError('maxlength')" class="error-text">Max 50 characters
                            </span>
                                    <span *ngIf="form.controls.last_name.hasError('pattern') && !form.controls.last_name.hasError('maxlength') && !form.controls.last_name.hasError('minlength')"
                                          class="error-text">please enter a valid last name
                            </span>
                                </div>

                                <div class="form-group  has-icon  inner-addon right-addon label-floating"
                                     [ngClass]="{'has-error': form.controls.email.invalid && (form.controls.email.touched || form.controls.email.dirty)}">
                                     <input name="email" id="email" formControlName="email" type="text"
                                           placeholder=" "
                                           class="form-control  pt-0">
                                           <label for="email" class="control-label  pt-2 mb-1">email*</label>
                                    <span *ngIf="form.controls.email.hasError('required')" class="error-text">please enter a valid email</span>
                                    
                                    <span *ngIf="form.controls.email.hasError('pattern')" class="error-text">please enter a valid email
                            </span>

                                </div>

                                <div class="form-group  has-icon  inner-addon right-addon label-floating"
                                     [ngClass]="{'has-error': form.controls.phone.invalid && (form.controls.phone.touched || form.controls.phone.dirty)}">
                                    <input name="phone" id="phone" formControlName="phone" type="text"
                                           placeholder=" "
                                           class="form-control  pt-0">
                                           <label for="phone" class="control-label  pt-2 mb-1">phone*</label>
                                           <span *ngIf="form.controls.phone.hasError('required')" class="error-text">please enter a valid phone number
                            </span>
                                    <span *ngIf="form.controls.phone.hasError('pattern')" class="error-text">please enter a valid phone number
                            </span>

                                </div>

                                <div class="form-group label-floating select"
                                     [ngClass]="{'has-error': form.controls['area_of_interest'].invalid && (form.controls['area_of_interest'].touched || form.controls['area_of_interest'].dirty) }">
                                    
                                    <select formControlName="area_of_interest" id="area_of_interest"
                                            class="form-control  py-0">
                                        <option value="" disabled selected hidden>select your area of interest</option>
                                            <option *ngFor='let state of cmsData.acf?.corporate_modal.area_of_interest' [value]="state.area_of_interest_item">{{state.area_of_interest_item}}</option>

                                    </select>
                                    <label for="area_of_interest" class="control-label  pt-2 mb-1">
                                        area of interest*</label>
                                    <span *ngIf="form.controls.area_of_interest.hasError('required')"
                                          class="error-text">please make a selection
                            </span>
                                </div>


                            </form>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row">
                <div class="mx-auto col-md-4  col-lg-4 col-xl-4 col-sm-6 col-xs-8 offset-0 offset-lg-1 offset-xl-1 offset-md-1">

                        <button class="btn btn-block btn-blue error-modal-close-btn" type="submit"
                                (click)="submit($event)">
                            Submit
                        </button>

                </div>
            </div>
        </div>
        <div *ngIf="status=='success'">
            <div class="d-flex justify-content-center mt-4">
                <div class="col-md-10 col-lg-10 col-xl-10 col-sm-12 col-xs-12 px-0 text-center">
                    <h3 class="modal-title text-red text-center nimbussans_bold tc-modal-title"
                        style="line-height: 34px;">
                        {{cmsData.acf?.corporate_modal_success.success_header_copy}} </h3>
                    <br>
                    <p class="gray" style="font-size:18px"> {{cmsData.acf?.corporate_modal_success.success_subheader_copy}}</p>
                    <div class="d-flex align-items-end flex-column" style="min-height: 50px;">
                        <div class="mt-auto align-self-center">
                            <button class="btn btn-blue btn-block error-modal-close-btn"
                                    (click)="modalUIRef.close()">
                                GOT IT
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</ib-modal>
