import {AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Inject, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';

declare let $: any;

@Component({
    selector: 'ib-modal',
    templateUrl: './modal.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements AfterViewInit {

    @ViewChild('modalRef') modalRef: ElementRef;
    @Output('onReady') onReady = new EventEmitter<ModalComponent>();
    @Output('onShow') onShow = new EventEmitter<any>();
    @Output('onClose') onClose = new EventEmitter<any>();

    constructor() {

    }

    ngAfterViewInit() {
        this.onReady.emit(this);
    }

    show() {
        $(this.modalRef.nativeElement).modal('show');
        this.onShow.emit();
    }

    close() {
        $(this.modalRef.nativeElement).modal('hide');
        this.onClose.emit();
    }

}
