export class MemberActivity implements IMemberActivity {
    ActivityUnitStatus: any;
    ActivityId: number;
    UnitsAcquired: number;
    UnitsConsumed: number;
    UnitsAvailable: number;
    ItemCode: string;
    ItemName: string;
    ExpirationDate: Date;
    StatusId: number;
    NumberOfSessionScheduled: number;
    bundleId?: number;
    constructor() {
    }
}

export interface IMemberActivity {
    ActivityUnitStatus: any;
    ActivityId: number;
    UnitsAcquired: number;
    UnitsConsumed: number;
    UnitsAvailable: number;
    ItemCode: string;
    ItemName: string;
    ExpirationDate: Date;
    StatusId: number;
    NumberOfSessionScheduled: number;
    bundleId?: number;
}









