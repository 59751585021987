<h3 class="sidebar-title text-dark-gray text-left pt-4 mb-3 nimbussans_bold">Account Information</h3>
<div class="row" *ngIf="mosoUser">
	<div class="col pb-3 text-center">
		<img class="img-responsive rounded-circle " src="./assets/img/fpo_avatar.png">
		<button (click)="popover()" type="button" class="btn bg-white btn-navy btn-popover nimbussans_bold rounded-circle hidden" data-toggle="popover" data-trigger="focus"
		        data-placement="bottom" data-content="You can only take or change your photo at the gym. It's because we like seeing you.">?
		</button>
	</div>
</div>
<div class="row" *ngIf="mosoUser">
	<div class="col">
		<div id="accordion" role="tablist" class="pt-5">
			<div class="card mb-0" *ngIf="mosoUser.RoleId">
				<div class="card-header p-0">
					<span class="left-side-bar-item accordion-title">Member Id: {{mosoUser.RoleId}}</span>
				</div>
			</div>
			<div class="card mb-0" *ngIf="(balance || balance === 0) && plan">
				<div class="card-header p-0" role="tab" id="headingOne">
					<a class="see-details first-tab px-3 pb-0" data-toggle="collapse" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">{{ plan.title }}
						<i class="ib-icon-chevron-down hide-when-accordion-open right-arrow position-relative float-right"></i>
					</a>
				</div>
				<div id="collapseOne" class="collapse p-0" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
					<div class="card-body p-0">
						<h4 class="text-uppercase p-3 my-0 text-navy nimbussans_bold">Plan details</h4>
						<p class="px-3">{{ plan.content_1 }}</p>
						<p class="px-3" [innerHTML]="plan.content_2"></p>
						<ul class="px-3" *ngIf="plan">
							<li class="text-navy nimbussans_bold pb-1"  *ngFor="let item of plan.list_1">
								<i class="fa fa-check pr-3" aria-hidden="true"></i>{{ item }}
							</li>
							<li><hr class="bg-navy my-3"></li>
							<li class="text-navy nimbussans_bold pb-1"  *ngFor="let item of plan.list_2">
								<i class="fa fa-check pr-3" aria-hidden="true"></i>{{ item }}
							</li>
						</ul>
					</div>
					<div class="card-body p-0">
						<a class="p-3" data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
							<span class="text-uppercase label-arrow-bottom position-relative">hide details</span>
							<i class="ib-icon-chevron-up right-arrow-bottom position-relative float-right pr-3"></i>
						</a>
					</div>
				</div>
			</div>
			<div class="card mb-0" *ngIf="balance || balance === 0">
				<div class="card-header p-0" role="tab" id="headingTwo">
					<a class="px-3 pt-3 pb-0" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
						<span class="accordion-title" [innerHtml]="isPastDue ? 'Account Status: <span>Late</span>' : 'Account Status: Up to date'"></span>
						<!-- <br> <span class="text-uppercase hide-when-accordion-open">See Details</span> -->
						<i class="ib-icon-chevron-down hide-when-accordion-open right-arrow position-relative float-right"></i> </a>
				</div>
				<div id="collapseTwo" class="collapse p-0" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
					<div class="card-body p-0">
						<p class="m-0 p-3" *ngIf="balance <= 0">Thank you, your account is up to date.</p>
						<p class="m-0 p-3 text-blue nimbussans_bold" *ngIf="balance > 0">
              Payment Due: ${{balance}}
              <ng-container *ngIf="isPastDue">
                Late
              </ng-container>
            </p>
						<p class="m-0 p-3 text-blue" *ngIf="isCardExpired && balance <= 0">Your card is expired</p>
						<p class="m-0 p-3" *ngIf="isCardExpired && balance <= 0">
							Please replace your card before your next payment. To pay by cash, pay at the gym no later than the day prior to your billing date. If you need help you can call your gym or email us at:
						</p>
						<p class="m-0 p-3 email-billing position-relative text-blue" *ngIf="isCardExpired && balance <= 0">blinkms@blinkfitness.com</p>
						<hr>
						<p class="m-0 px-3 pt-3" *ngIf="!isMosoMemberHasCancellationDate() && !memberHasNoActiveMembership">
              Next billing date: {{nextBillingDate}}
            </p>
						<p class="m-0 p-3" *ngIf="!isMosoMemberHasCancellationDate()">To pay by cash, pay at the gym no later than the day prior to your billing date</p>
						<hr *ngIf="!isMosoMemberHasCancellationDate()">
					</div>
					<div class="card-body p-0">
						<a class=" p-3" data-toggle="collapse" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
							<span class="text-uppercase label-arrow-bottom position-relative">hide details</span>
							<i class="ib-icon-chevron-up right-arrow-bottom position-relative float-right pr-3"></i>
						</a>
					</div>
				</div>
			</div>
			<div class="card mb-0">
				<div class="card-header p-0" role="tab" id="headingTwo">
					<a class=" p-3 cursor-pointer" (click)="onMyPersonalTraining()">
						<span class="accordion-title">My Personal Training</span>
						<!-- <br> <span class="text-uppercase hide-when-accordion-open">See Details</span> -->
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
