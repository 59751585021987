import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';


@Component({
    selector: 'ib-checkout-layout',
    templateUrl: './checkout-layout.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CheckoutLayoutComponent implements OnInit, OnDestroy {

    constructor() {
    }

    ngOnInit(): void {

    }


    ngOnDestroy(): void {
    }
}
