import { IPrivacyPolicyState } from '../../reducers/privacy-policy/privacy-policy.reducer.types';
import { IBaseAction } from '../common.types';

export enum PrivacyPolicyActionTypes {
  request = '[Privacy Policy] Load',
  requestSuccess = '[Privacy Policy] Load Success',
  requestFailure = '[Privacy Policy] Load Failure',
}

export interface IPrivacyPolicyAction extends IBaseAction {
  data: IPrivacyPolicyState;
}

export interface IPrivacyPolicyRequest {
  slug: string;
}
