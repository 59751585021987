/**
 * CreditCard
 * */
export class CreditCardUpdate implements ICreditCardUpdate {
    mosoId: string;
    facilityId: string;
    CreditCardNumber: number;
    AccountAccessory: string;
    PaySourceId: number | null;
    CreditCardDetails: CreditCardDetails;
    Description: string;
    OnAccountFundingSource: boolean;
    Invalid: boolean;
    IsActive: boolean;
    UseForRecurring: boolean;
    UseForNonRecurring: boolean;
    AccountHolderName: string;
    Address: string;
    Address2: string;
    City: string;
    StateProvince: string;
    PostalCode: number;
    Country: string;
    Phone: string;
    EmailAddress: string;
    DigitalSignature: string;
    termsOfUse: string;

    constructor() {
    }
}

export interface ICreditCardUpdate {
    mosoId: string;
    facilityId: string;
    CreditCardNumber: number;
    AccountAccessory: string;
    PaySourceId: number;
    CreditCardDetails: CreditCardDetails;
    Description: string;
    OnAccountFundingSource: boolean;
    Invalid: boolean;
    IsActive: boolean;
    UseForRecurring: boolean;
    UseForNonRecurring: boolean;
    AccountHolderName: string;
    Address: string;
    Address2: string;
    City: string;
    StateProvince: string;
    PostalCode: number;
    Country: string;
    Phone: string;
    EmailAddress: string;
    DigitalSignature: string;
    termsOfUse: string;
}

export interface CreditCardDetails {
    CreditCardType: string;
    ExpirationMonth: number;
    ExpirationYear: number;
}
